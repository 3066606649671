import makeValidationSchema from './PartyMakeValidationSchema'
import { useTranslation, constants } from 'cng-web-lib'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import FdapnKeys from 'src/constants/locale/key/Fdapn'
import { Box, IconButton, Card, CardContent, Grid, Divider, Typography, InputAdornment } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import { useFormContext, useWatch } from 'react-hook-form';
import { NaAcePartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NaStateCodeAutoCompleteField } from 'src/components/na'
import TemplateSelectDialog from './../../../components/aciacehighway/TemplateSelectDialog'
import PartyMasterApiUrls from './../../../apiUrls/PartyMasterApiUrls'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import AddPartyDetailSection from './sections/AddPartyDetailSection'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngSelectField,
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCheckboxField,
      CngDateField,
      CngTimeField,
      CngCountryAutocompleteField,
    },
  },
  CngGridItem,
} = components

const {
  filter: { LIKE, EQUAL, IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  _pgaId: 0,
  partyPgaId: 0,
  partyType: "",
  partyIdType: "",
  partyIdTypeValue: "",
  name: "",
  address1: "",
  address2: "",
  apartmentSuiteNumber: "",
  cityName: "",
  stateProvince: "",
  postalCode: "",
  countryCode: "",
  contactFirstName: "",
  contactPhoneNumber: "",
  email: "",
  version: "",
  savePartyFlag: false,
  partyIdentification: "",
  partyDropDown: "",
  addParty: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index
}) {
  const { translate } = useTranslation(Namespace.FDAPN)
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const { savePartyFlag, partyType, partyIdType, countryCode, stateProvince, addParty } = useWatch()
  const { getValues, setValue, reset, trigger } = useFormContext();
  const [templateDialog, setTemplateDialog] = useState(false)
  const [lookups, setLookups] = useState(null)
  const [additionalPartyDetailExplanded, setAdditionalPartyDetailExplanded] = useState(false)

  function getLookupValue(name, value) {
    if (!lookups) return value
    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  useEffect(() => {
    if (addParty && (addParty.name || addParty.address1 || addParty.address2 || addParty.address3 || addParty.address4 || addParty.address5 || addParty.contactName || addParty.cityName || addParty.email)) {
      setAdditionalPartyDetailExplanded(true);
    }
  }, []);

  const columns = [
    {
      field: 'partyidn',
      title: getTranslatedText('FDAPN', 'partyIdentification')
    },
    {
      field: 'partytype',
      title: getTranslatedText('FDAPN', 'partyType'),
      render: (data) =>
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('partyType', data.partytype)}
        </Typography>
    },
    {
      title: getTranslatedText('FDAPN', 'partyName'),
      render: data => [data.partyname1, data.partyname2].join(' ')
    },
    {
      field: 'statecode',
      title: getTranslatedText('FDAPN', 'partyStateProvince')
    },
    {
      field: 'countrycode',
      title: getTranslatedText('FDAPN', 'partyCountryCode')
    },
  ]

  function handleApplyTemplate(template) {
    if (!template) {
      reset()
      return
    }

    const {
      partyidn,
      partytype,
      partyname1,
      address1,
      address2,
      cityname,
      postalcode,
      countrycode,
      statecode,
      contactperson,
      telephonenumber,
      faxNo,
      email,
      partyIdentifier,
      secondaryInfoType,
      secondaryInfo
    } = template

    setValue('partyId', partyidn || '', { shouldDirty: true })
    setValue('partyType', partytype, { shouldDirty: true })
    setValue('name', partyname1, { shouldDirty: true })
    setValue('address1', address1, { shouldDirty: true })
    setValue('address2', address2, { shouldDirty: true })
    setValue('cityName', cityname, { shouldDirty: true })
    setValue('postalCode', postalcode, { shouldDirty: true })
    setValue('stateProvince', statecode, { shouldDirty: true })
    setValue('countryCode', countrycode, { shouldDirty: true })
    setValue('contactFirstName', contactperson, { shouldDirty: true })
    setValue('contactPhoneNumber', telephonenumber, { shouldDirty: true })
    setValue('email', email, { shouldDirty: true })

    if (partyIdentifier != null && partyIdentifier != undefined && partyIdentifier != "") {
      for (var i = 0, l = partyIdentifier.length; i < l; i++) {
        var obj = partyIdentifier[i];
        setValue('partyIdType', obj.partyIdentifierCode || '', { shouldDirty: true })
        setValue('partyIdTypeValue', obj.partyIdentifierNumber || '', { shouldDirty: true })
      }
    }
    trigger()
  }

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `party.${index}.${field}` : field
  }, [])

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <CngGridItem xs={12} lg={4}>
            </CngGridItem>
            <CngGridItem xs={12} lg={8}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.savePartyFlag}>
                  <CngCheckboxField
                    label={
                      <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                        {getTranslatedText('FDAPN', 'savePartyFlag')}
                      </Typography>
                    }
                    name='savePartyFlag'
                    disabled={disabled}
                    onChange={(e) => {
                      setValue('savePartyFlag', e.target.checked)

                      if (!e.target.checked) {
                        setValue('partyIdentification', "")
                      }
                    }}
                    size='small'
                    style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.partyId}>
                  <CngTextField
                    name={typeof index === 'number' ? `party.${index}.partyId` : 'partyId'}
                    inputProps={{
                      style: { textTransform: 'uppercase' },
                      maxLength: 35
                    }}
                    label={getTranslatedText('FDAPN', 'partyIdentification')}
                    disabled={disabled || !savePartyFlag}
                    onChange={(e) => {
                      setValue('partyId', e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={4}>
                  <NaAcePartyMasterAutoCompleteField
                    name='partyDropDown'
                    label={getTranslatedText('FDAPN', 'partyIdLookup')}
                    disabled={disabled}
                    onChange={(_, options) => handleApplyTemplate(options.data)}
                    lookupProps={{
                      filters: [{
                        field: 'partyId',
                        operator: EQUAL,
                        value: FileForUserGetPartyId()
                      },
                      { field: 'partytype', operator: IN, value: ['DFP', 'FDC', 'FD1', 'DFI', 'LG', 'MF', 'PNS', 'PNT', 'DEQ', 'UC'] }
                      ]
                    }}
                    size='small'
                    fullWidth
                    disableClearable
                    textFieldProps={{
                      InputProps: {
                        customEndAdornment: () => (
                          <InputAdornment position='end' style={{ marginTop: -16 }}>
                            <IconButton onClick={(event) => {
                              event.stopPropagation()
                              setTemplateDialog(true)
                            }}>
                              <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }}
                    forcePopupIcon={false}
                  />
                </CngGridItem>
              </Grid>
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.partyType}>
              <CngCodeMasterAutocompleteField
                required
                name={typeof index === 'number' ? `party.${index}.partyType` : 'partyType'}
                label={getTranslatedText('FDAPN', 'partyType')}
                codeType='FDA_PN_PARTY_TYPE'
                size='small'
                disabled={disabled}
                key={partyType}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.partyIdType}>
              <CngCodeMasterAutocompleteField
                name={typeof index === 'number' ? `party.${index}.partyIdType` : 'partyIdType'}
                label={getTranslatedText('FDAPN', 'partyIdType')}
                codeType='FDA_PN_PARTY_IDENTIFIER_CODE'
                size='small'
                disabled={disabled}
                key={partyIdType}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.partyIdTypeValue}>
              <CngTextField
                required={partyIdType}
                name={typeof index === 'number' ? `party.${index}.partyIdTypeValue` : 'partyIdTypeValue'}
                label={getTranslatedText('FDAPN', 'partyIdTypeValue')}
                inputProps={{ maxLength: 15 }}
                onBlur={(e) => {
                  setValue('partyIdTypeValue', e.target.value.toUpperCase())
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.name}>
              <CngTextField
                required
                name={typeof index === 'number' ? `party.${index}.name` : 'name'}
                label={getTranslatedText('FDAPN', 'partyName')}
                inputProps={{ maxLength: 32 }}
                onChange={(e) => {
                  setValue('name', e.target.value.toUpperCase())
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.apartmentSuiteNumber}>
              <CngTextField
                name={typeof index === 'number' ? `party.${index}.apartmentSuiteNumber` : 'apartmentSuiteNumber'}
                label={getTranslatedText('FDAPN', 'partyApartmentSuiteNumber')}
                onChange={(e) => {
                  setValue('apartmentSuiteNumber', e.target.value.toUpperCase())
                }}
                inputProps={{ maxLength: 5 }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} >

            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.address1}>
              <CngTextField
                required={partyType === "DFP" || partyType === "FDC" || partyType === "FD1" || partyType === "DFI" || partyType === "LG" || partyType === "DEQ" || partyType === "UC"}
                name={typeof index === 'number' ? `party.${index}.address1` : 'address1'}
                label={getTranslatedText('FDAPN', 'partyAddress1')}
                onChange={(e) => {
                  setValue('address1', e.target.value.toUpperCase())
                }}
                inputProps={{ maxLength: 23 }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.address2}>
              <CngTextField
                name={typeof index === 'number' ? `party.${index}.address2` : 'address2'}
                label={getTranslatedText('FDAPN', 'partyAddress2')}
                onChange={(e) => {
                  setValue('address2', e.target.value.toUpperCase())
                }}
                inputProps={{ maxLength: 32 }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={4} lg={4} shouldHide={shouldHideMap?.countryCode}>
              <CngCountryAutocompleteField
                required
                name={typeof index === 'number' ? `party.${index}.countryCode` : 'countryCode'}
                inputProps={{ maxLength: 2 }}
                label={getTranslatedText('FDAPN', 'partyCountryCode')}
                disabled={disabled}
                key={countryCode}
                lookupProps={{
                  label: (record) => `${record.code};${record.descriptionEn}`
                }}
                onChange={(e) => {
                  setValue(typeof index === 'number' ? `party.${index}.stateProvince` : 'stateProvince', '')
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.stateProvince}>
              <NaStateCodeAutoCompleteField
                name={typeof index === 'number' ? `party.${index}.stateProvince` : 'stateProvince'}
                label={getTranslatedText('FDAPN', 'partyStateProvince')}
                disabled={disabled}
                countryCode={countryCode}
                key={countryCode + stateProvince}
                required={countryCode === 'CA' || countryCode === 'US'}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.cityName}>
              <CngTextField
                required
                name={typeof index === 'number' ? `party.${index}.cityName` : 'cityName'}
                label={getTranslatedText('FDAPN', 'partyCityName')}
                onChange={(e) => {
                  setValue('cityName', e.target.value.toUpperCase())
                }}
                inputProps={{ maxLength: 21 }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.postalCode}>
              <CngTextField
                name={typeof index === 'number' ? `party.${index}.postalCode` : 'postalCode'}
                label={getTranslatedText('FDAPN', 'partyPostalCode')}
                onChange={(e) => {
                  setValue('postalCode', e.target.value.toUpperCase())
                }}
                inputProps={{ maxLength: 9 }}
                required={countryCode === 'CA' || countryCode === 'US'}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.contactFirstName}>
              <CngTextField
                required={partyType === "PNS" || partyType === "PNT"}
                name={typeof index === 'number' ? `party.${index}.contactFirstName` : 'contactFirstName'}
                label={getTranslatedText('FDAPN', 'partyContactFirstName')}
                onChange={(e) => {
                  setValue('contactFirstName', e.target.value.toUpperCase())
                }}
                inputProps={{ maxLength: 23 }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.contactPhoneNumber}>
              <CngTextField
                required={partyType === "PNS" || partyType === "PNT"}
                name={typeof index === 'number' ? `party.${index}.contactPhoneNumber` : 'contactPhoneNumber'}
                label={getTranslatedText('FDAPN', 'partyContactPhoneNumber')}
                onChange={(e) => {
                  setValue('contactPhoneNumber', e.target.value.toUpperCase())
                }}
                inputProps={{ maxLength: 15 }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.email}>
              <CngTextField
                required={partyType === "PNS" || partyType === "PNT"}
                name={typeof index === 'number' ? `party.${index}.email` : 'email'}
                label={getTranslatedText('FDAPN', 'partyEmail')}
                onChange={(e) => {
                  setValue('email', e.target.value.toUpperCase())
                }}
                inputProps={{ maxLength: 35 }}
                size='small'
              />
            </CngGridItem>
            <Grid item xs={12}>
              <CollapsibleSection
                defaultExpanded={additionalPartyDetailExplanded}
                title={getTranslatedText('FDAPN', 'additionalPartyDetails')}
              >
                <Grid container spacing={1}>
                  <AddPartyDetailSection
                    showNotification={showNotification}
                    index={index}
                  />
                </Grid>
              </CollapsibleSection>
            </Grid>
            <TemplateSelectDialog
              columns={columns}
              defaultSorts={[{ field: 'partyidn', direction: 'ASC' }]}
              fetch={{ url: PartyMasterApiUrls.SEARCH }}
              fetchFilters={[
                { field: 'partytype', operator: IN, value: ['DFP', 'FDC', 'FD1', 'DFI', 'LG', 'MF', 'PNS', 'PNT', 'DEQ', 'UC'] },
                { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
              ]}
              onApplyTemplate={handleApplyTemplate}
              onClose={() => setTemplateDialog(false)}
              open={templateDialog}
              search={{ field: 'partyidn', operator: LIKE }}
              title='Party Template'
            />
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

const PartyFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PartyFormProperties
