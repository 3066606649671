import makeValidationSchema from './AffirmationMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import FdapnKeys from 'src/constants/locale/key/Fdapn'
import { Card, CardContent, Grid } from '@material-ui/core'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import { useFormContext, useWatch } from 'react-hook-form';
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngSelectField,
      CngTextField,
      CngUomAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  complianceAffirmationCode: "",
  complianceAffirmationDesc: "",
  version: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index
}) {
  const { translate } = useTranslation(Namespace.FDAPN)
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const { getValues, setValue, reset, trigger } = useFormContext();
  const { complianceAffirmationCode } = useWatch()

  return (
    <Grid container spacing={2}>
      <CngGridItem xs={12} md={6}>
        <CngCodeMasterAutocompleteField
          name={typeof index === 'number' ? `affirmation.${index}.complianceAffirmationCode` : 'complianceAffirmationCode'}
          label={getTranslatedText('FDAPN', 'affirmationCode')}
          codeType='FDA_PN_AOC'
          size='small'
          key={complianceAffirmationCode}
        />
      </CngGridItem>
      <CngGridItem xs={12} md={6}>
        <CngTextField
          name={typeof index === 'number' ? `affirmation.${index}.complianceAffirmationDesc` : 'complianceAffirmationDesc'}
          label={getTranslatedText('FDAPN', 'affirmationDescription')}
          onChange={(e) => {
            setValue(typeof index === 'number' ? `affirmation.${index}.complianceAffirmationDesc` : 'complianceAffirmationDesc', e.target.value.toUpperCase())
          }}
          inputProps={{ maxLength: 30 }}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const AffirmationFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AffirmationFormProperties
