import React, { useEffect, useState, useContext } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import PNDetailsDialog from './PNDetailsDialog'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext'
import moment from 'moment-timezone'
import TinyChip from '../../../components/aciacehighway/TinyChip'

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateField,
      CngTimeField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL }
} = constants

function PNDetailsTable(props) {
  const {
    data,
    onAddPNDetail,
    onDeletePNDetail,
    onEditPNDetail,
    onAddPartyDetail,
    onDeletePartyDetail,
    onEditPartyDetail,
    showNotification,
    shouldHideMap,
    disabled,
  } = props

  const [lookups, setLookups] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [pgaDTODialog, setpgaDTODialog] = useState({
    isView: false,
    open: false,
    pgaDTO: null
  })
  const { fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const classes = useStyles()
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  // const { getValues, setValue, reset, trigger } = useFormContext();

  const filtered =
    searchTerm !== ''
      ? data.filter((pgaDTO) =>
        pgaDTO.productNumber
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
      : data

  useEffect(() => {
    Promise.all([
      // Gov Agency Processing
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'FDA_PN_GOV_AGENCY_PROCESSING' }],
        undefined,
        'code'
      ),
      // Arrival Location
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [
            { field: 'indicator', operator: EQUAL, value: 'ACI' }
          ],
          customData: { codeMType: 'CustomsOffice' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
    ]).then(
      ([
        govAgencyProcessing,
        arrivalLocation,
      ]) => {
        setLookups({
          govAgencyProcessing,
          arrivalLocation
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const combineDateAndTime = (date, time) => {
    if (date && time) {
      const dateTime = moment(date).set({
        hour: parseInt(time.substring(0, 2), 10),
        minute: parseInt(time.substring(2, 4), 10),
      });
      return dateTime.format('D MMM YYYY HH:mm');
    }
    return "";
  };

  return (
    <>
      <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {data.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddPNDetail && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={() =>
                  setpgaDTODialog({
                    open: true,
                    isView: false,
                    pgaDTO: null
                  })
                }
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                Add Prior Notice
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              <TableCell>{getTranslatedText('FDAPN', 'basketNumber')}</TableCell>
              <TableCell>{getTranslatedText('FDAPN', 'pgaProductNumber')}</TableCell>
              <TableCell>{getTranslatedText('FDAPN', 'pgaGovAgencyProcessingCode')}</TableCell>
              <TableCell>{getTranslatedText('FDAPN', 'pgaAnticipatedArrivalDate')}</TableCell>
              <TableCell>{getTranslatedText('FDAPN', 'pgaArrivalLocation')}</TableCell>
              <TableCell>{getTranslatedText('FDAPN', 'pgaPnConfirmationNumber')}</TableCell>
              {(onEditPNDetail || onDeletePNDetail) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.length > 0 ? (
              filtered.map((pgaDTO) => (
                <TableRow
                  key={Math.random()}
                  onClick={() =>
                    setpgaDTODialog({ open: true, isView: true, pgaDTO })
                  }
                >
                  <TableCell>{pgaDTO.basketNumber}</TableCell>
                  <TableCell>{pgaDTO.productNumber}</TableCell>
                  <TableCell>{getLookupValue('govAgencyProcessing', pgaDTO.govAgencyProcessingCode)}</TableCell>
                  <TableCell>{combineDateAndTime(pgaDTO.anticipatedArrivalDate, pgaDTO.anticipatedArrivalTime)}</TableCell>
                  <TableCell><Typography component='div' variant='inherit'>
                    {getLookupValue('arrivalLocation', pgaDTO.arrivalLocation)}
                    <TinyChip label={pgaDTO.arrivalLocation} variant='outlined' />
                  </Typography></TableCell>
                  <TableCell>{pgaDTO.pnConfirmationNumber}</TableCell>
                  {(onEditPNDetail || onDeletePNDetail) && (
                    <TableCell align='right'>
                      <Grid container justify='flex-end' spacing={1}>
                        {onEditPNDetail && (
                          <Grid item xs='auto'>
                            <Tooltip placement='bottom' title='Edit'>
                              <span>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'pen']}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    setpgaDTODialog({ open: true, isView: false, pgaDTO })
                                  }}
                                  size='small'
                                />
                              </span>
                            </Tooltip>
                          </Grid>
                        )}
                        <Grid item xs='auto'>
                          <Tooltip placement='bottom' title='Clone'>
                            <span>
                              <CngIconButton
                                icon={['fal', 'copy']}
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onAddPNDetail({ ...pgaDTO, id: undefined })
                                }}
                                size='small'
                                type='outlined'
                              />
                            </span>
                          </Tooltip>
                        </Grid>
                        {onDeletePNDetail && (
                          <Grid item xs='auto'>
                            <Tooltip placement='bottom' title='Delete'>
                              <span>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'trash']}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    onDeletePNDetail(pgaDTO)
                                  }}
                                  size='small'
                                />
                              </span>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={3 + (onEditPNDetail || onDeletePNDetail ? 1 : 0)}
                >
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PNDetailsDialog
        isView={pgaDTODialog.isView}
        open={pgaDTODialog.open}
        onClose={() =>
          setpgaDTODialog({ open: false, isView: false, pgaDTO: null })
        }
        onAddPNDetailDTO={onAddPNDetail}
        onEditPNDetailDTO={onEditPNDetail}
        onAddPartyDetail={onAddPartyDetail}
        onDeletePartyDetail={onDeletePartyDetail}
        onEditPartyDetail={onEditPartyDetail}
        showNotification={showNotification}
        pgaDTO={pgaDTODialog.pgaDTO}
        getLookupValue={getLookupValue}
      />
    </>
  )
}

export default PNDetailsTable
