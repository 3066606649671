import makeValidationSchema from './StatusNotificationMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants
} from 'cng-web-lib'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import NaAciHwAttachedCCNAutoComField from 'src/components/na/autocomplete/codemaintenance/NaAciHwAttachedCCNAutoComField'
import { useFormContext, useWatch } from "react-hook-form";
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'


const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngSelectField,
      CngLookupAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  headerId: "",
  name: "",
  email: "",
  phoneNumber: "",
  statusNotificationType: "",
  version: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  headerId
}) {
  const { setValue, getValues, reset } = useFormContext();
  const { getTranslatedText } = useContext(FdaPNTranslationContext)

  let pnHeaderId = getValues("headerId");
  console.log("pnHeaderId", pnHeaderId)

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  return (
    <Card>
      <CngSimpleCardHeader title={getTranslatedText('FDAPN', 'statusNotification')} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.name}>
            <CngTextField
              required
              name="name"
              label={getTranslatedText('FDAPN', 'name')}
              disabled={disabled}
              onChange={(e) => {
                setValue("name", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.statusNotificationType}>
            <CngCodeMasterAutocompleteField
              name="statusNotificationType"
              label={getTranslatedText('FDAPN', 'snType')}
              disabled={disabled}
              codeType='FDA_PN_SN_TYPE'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.phoneNumber}>
            <CngTextField
              name="phoneNumber"
              label={getTranslatedText('FDAPN', 'phoneNumber')}
              disabled={disabled}
              onChange={(e) => {
                setValue("phoneNumber", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.email}>
            <CngTextField
              name="email"
              label={getTranslatedText('FDAPN', 'email')}
              disabled={disabled}
              onChange={(e) => {
                setValue("email", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const StatusNotificationFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default StatusNotificationFormProperties
