import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, constants } from 'cng-web-lib'
import { Box, Grid } from '@material-ui/core'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngField from 'src/components/cngcomponents/CngField'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext'
import BillOfLadingFormProperties from './BillOfLadingFormProperties'

const {
  button: { CngButton, CngPrimaryButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function BillOfLadingDialog(props) {
  const {
    onAddBOLDTO,
    onEditBOLDTO,
    isView,
    onClose,
    open,
    showNotification,
    bolDTO,
    getLookupValue
  } = props

  const { initialValues, makeValidationSchema } = BillOfLadingFormProperties.formikProps
  const { getTranslatedText } = useContext(FdaPNTranslationContext)

  return (
    <CngDialog
      dialogContent={
        isView && bolDTO ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CngSection title={getTranslatedText('FDAPN', 'bolTittle')}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={getTranslatedText('FDAPN', 'billType')}>
                      {getLookupValue('billType', bolDTO.billType)}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={getTranslatedText('FDAPN', 'bolIssuerCode')}>
                      {bolDTO.bolIssuerCode}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={getTranslatedText('FDAPN', 'bolNumber')}>
                      {bolDTO.bolNumber}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
          </Grid>
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: bolDTO || initialValues,
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (bolDTO) {
                  onEditBOLDTO(data)
                } else {
                  onAddBOLDTO(data)
                }

                onClose()
              }
            }}
            formState={FormState.COMPLETED}
            renderBodySection={(labelMap, shouldHideMap) => (
              <Box padding={1}>
                <BillOfLadingFormProperties.Fields
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                />
              </Box>
            )}
            innerForm={true}
            renderButtonSection={() => (
              <Box display='flex' justifyContent='flex-end'>
                <CngPrimaryButton type='submit'>
                  {bolDTO
                    ? getTranslatedText('FDAPN', 'updateButton')
                    : getTranslatedText('FDAPN', 'saveButton')}
                </CngPrimaryButton>
              </Box>
            )}
          />
        )
      }
      dialogAction={
        isView ? <CngButton onClick={onClose}>OK</CngButton> : null
      }
      dialogTitle={getTranslatedText('FDAPN', 'bolTitle')}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default BillOfLadingDialog
