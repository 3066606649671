import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    partyType: Yup.string().required(requiredMessage).nullable(),
    partyIdType: Yup.string().nullable(),
    partyIdTypeValue: Yup.string().nullable(),
    name: Yup.string().required(requiredMessage).nullable(),
    address1: Yup.string().when('partyType', {
      is: (value) => value !== "PNS" && value !== "PNT" && value !== "MF",
      then: Yup.string().required(requiredMessage).nullable(),
      otherwise: Yup.string().nullable()
    }).nullable(),
    address2: Yup.string().nullable(),
    apartmentSuiteNumber: Yup.string().nullable(),
    cityName: Yup.string().required(requiredMessage).nullable(),
    stateProvince: Yup.string().when('countryCode', {
      is: (value) => value === "US" || value === "CA",
      then: Yup.string().required(requiredMessage).nullable(),
      otherwise: Yup.string().nullable()
    }).nullable(),
    postalCode: Yup.string().when('countryCode', {
      is: (value) => value === "US" || value === "CA",
      then: Yup.string().required(requiredMessage).nullable(),
      otherwise: Yup.string().nullable()
    }).nullable(),
    countryCode: Yup.string().required(requiredMessage).nullable(),
    contactFirstName: Yup.string().nullable(),
    contactPhoneNumber: Yup.string().nullable(),
    email: Yup.string().email("Please enter a valid email").nullable(),
    addParty: Yup.object({
      name: Yup.string().nullable(),
      address1: Yup.string().nullable(),
      address2: Yup.string().nullable(),
      address3: Yup.string().nullable(),
      address4: Yup.string().nullable(),
      address5: Yup.string().nullable(),
      cityName: Yup.string().nullable(),
      contactName: Yup.string().nullable(),
      email: Yup.string().email("Please enter a valid email").nullable(),
    }).nullable()
  })
}

export default makeValidationSchema
