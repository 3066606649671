import React, { useRef, useEffect, useContext } from 'react'
import { components } from 'cng-web-lib'
import CngSection from '../../../../components/cngcomponents/CngSection'
import PackagingEntry from './../PackagingEntry'
import { Box, Grid, Tooltip, Divider } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import _ from 'lodash'
import PackagingFormProperties from './../PackagingFormProperties'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { FdaPNTranslationContext } from './../../contexts/FDAPNTranslationContext.js'

const {
  button: { CngIconButton },
  form: {
    field: {
      CngSelectField,
      CngTextField,
      CngUomAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem
} = components

const { initialValues } = PackagingFormProperties.formikProps

function PackagingSection(props) {
  const packaging = useWatch({ name: 'packaging' })
  const { showNotification, title, crewSectionHelper, shouldHideMap } = props

  const { getValues, setValue } = useFormContext()
  const lastIndex = useRef(packaging.length)
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const { uom, baseUnitUom, baseUnitQuantity } = useWatch()

  function handleAddPackaging(data) {
    const packagingList = [...getValues('packaging'), data].map(
      (packaging, index) => ({ ...packaging, _id: index + 1, packagingQualifier: index + 1 })
    )
    setValue('packaging', packagingList)
  }

  function handleDeletePackaging(data) {
    const clonedPackagingList = [...getValues('packaging')].filter(
      (packaging) => packaging.id ? packaging.id !== data.id : packaging._id !== data._id
    )
    const packagingList = clonedPackagingList.map(
      (packaging, index) => ({ ...packaging, _id: index + 1, packagingQualifier: index + 1 })
    )
    setValue('packaging', packagingList)
  }

  const totalPackaging = packaging && packaging.reduce((acc, item) => acc * item.quantity, 1);

  useEffect(() => {
    setValue('totalQuantity', totalPackaging ? totalPackaging * baseUnitQuantity : baseUnitQuantity)
  }, [totalPackaging]);

  return (
    <CngSection title={getTranslatedText('FDAPN', 'packagingTitle')} helperText={crewSectionHelper} alignItems='flex-end'>
      <Box display='flex' flexDirection='column' style={{ gap: 8 }}>
        {packaging.map((packagingDTO, index) => (
          <React.Fragment key={packagingDTO._id || packagingDTO.id || index}>
            <Grid item xs={12}>
              <PackagingEntry
                index={index}
                isLast={packaging.length === 1}
                onAddPackaging={handleAddPackaging}
                onDeletePackaging={handleDeletePackaging}
                packagingDTO={packagingDTO}
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
              />
            </Grid>
            {packaging.length !== index + 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>
      {packaging.length === 0 && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs='auto'>
              <Tooltip placement='bottom' title='Add new'>
                <span>
                  <CngIconButton
                    icon={['fal', 'plus']}
                    onClick={() => handleAddPackaging(initialValues)}
                    size='small'
                    type='outlined'
                  />
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )}
      &nbsp;
      <CngSection title={getTranslatedText('FDAPN', 'baseUnit')} helperText={crewSectionHelper} alignItems='flex-end'>
        <CngGridItem xs={12} md>
          <Grid container spacing={1}>
            <CngGridItem xs={12} md={4} lg={4}>
              <CngTextField
                name="baseUnitQuantity"
                label={getTranslatedText('FDAPN', 'baseQuantity')}
                inputProps={{ maxLength: 12 }}
                onBlur={(e) => {
                  setValue('baseUnitQuantity', e.target.value.toUpperCase())
                  setValue('totalQuantity', totalPackaging ? totalPackaging * baseUnitQuantity : baseUnitQuantity)
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={4} lg={4}>
              <CngCodeMasterAutocompleteField
                name="baseUnitUom"
                label={getTranslatedText('FDAPN', 'baseUOM')}
                codeType='FDA_PN_UOM'
                size='small'
                key={baseUnitUom}
              />
            </CngGridItem>
            <CngGridItem xs={12} md={4} lg={4}>
              <CngTextField
                disabled={true}
                name="totalQuantity"
                label={getTranslatedText('FDAPN', 'totalQuantity')}
                onBlur={(e) => {
                  setValue('totalQuantity', e.target.value.toUpperCase())
                }}
                size='small'
              />
            </CngGridItem>
          </Grid>
        </CngGridItem>
      </CngSection>
    </CngSection>
  )
}

export default PackagingSection
