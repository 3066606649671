import React, { useEffect, useState, useContext, useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, useServices, constants } from 'cng-web-lib'
import { Grid, Divider, Typography, Tooltip } from '@material-ui/core'
import { FdaPNTranslationContext } from '../../contexts/FDAPNTranslationContext.js'
import { NaCustomsOfficeAutocompleteField, NaSublocationWithoutCodeAutocompleteField } from 'src/components/na'
import { NaStateCodeAutoCompleteField } from 'src/components/na'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import Checkbox from 'src/components/Checkbox'
import { useLocation } from 'react-router-dom'
import TemplateSelectDialogWithOptionACIHWYCargo from 'src/components/aciacehighway/TemplateSelectDialogWithOptionACIHWYCargo'
import CngSection from '../../../../components/cngcomponents/CngSection.js'
import Switch from 'src/components/Switch'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import BillOfLadingFormProperties from '../BillOfLadingFormProperties.js';
import BillOfLadingTable from './../BillOfLadingTable.js'

const {
    button: { CngButton, CngIconButton },
    form: {
        field: {
            CngCheckboxField,
            CngTextField,
            CngDateField,
            CngTimeField,
            CngCodeMasterAutocompleteField,
            CngCountryAutocompleteField,
            CngSelectField,
            CngUomAutocompleteField
        },
    },
    CngGridItem,
} = components

const {
    filter: { LIKE, EQUAL, IN }
} = constants

const { initialValues } = BillOfLadingFormProperties.formikProps

function BillOfLadingSection(props) {
    const { disabled, shouldHideMap, user, blTypes, showNotification } = props
    const { getValues, setValue, reset, trigger } = useFormContext();
    const { securedSendRequest } = useServices()
    const { getTranslatedText } = useContext(FdaPNTranslationContext)
    const location = useLocation();
    const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false
    const { bol } = useWatch()

    function handleAddBOL(data) {
        const bolDTO = [...getValues('bol'), data].map(
            (bolDTO, index) => ({ ...bolDTO, _id: index + 1 }))

        setValue('bol', bolDTO, { shouldValidate: true })
    }

    function handleDeleteBOL(data) {
        const bolDTO = [...getValues('bol')].filter(
            (bolDTO) => bolDTO.id ? bolDTO.id !== data.id : bolDTO._id !== data._id
        )

        setValue('bol', bolDTO, { shouldValidate: true })
    }

    function handleEditBOL(data) {
        const bolDTO = [...getValues('bol')]
        const index = bolDTO.findIndex(
            (bolDTO) => bolDTO.id ? bolDTO.id === data.id : bolDTO._id === data._id
        )

        bolDTO[index] = data

        setValue('bol', bolDTO, { shouldValidate: true })
    }

    return (
        <CngSection title={getTranslatedText('FDAPN', 'bolTitle')}>
            <BillOfLadingTable
                data={bol}
                onAddBOL={handleAddBOL}
                onDeleteBOL={handleDeleteBOL}
                onEditBOL={handleEditBOL}
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
                disabled={disabled}
            />
        </CngSection>
    )
}

export default BillOfLadingSection