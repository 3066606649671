import FdapnProductMasterApiUrls from 'src/apiUrls/FdapnProductMasterApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect, useState, useContext, useCallback, useRef } from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import ConfirmDialog from '../../common/ConfirmDialog'
import { useFormContext, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom'
import withFormState from './../../../components/aciacehighway/hocs/withFormState'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification, onSetLoading }) {
  const loc = useLocation()
  const onSuccessCallback = useRef((e) =>
    history.push(`${pathMap.FDAPN_PRODUCTMASTER}/edit/` + e.id)
  )
  let formikPropsData;
  if (loc.state !== undefined && loc.state.shouldRerender === undefined) {
    formikPropsData = {
      ...FormProperties.formikProps,
      initialValues: loc.state,
    }
  } else {
    formikPropsData = FormProperties.formikProps
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={formikPropsData}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: FdapnProductMasterApiUrls.POST,
            successRedirect: pathMap.FDAPN_PRODUCTMASTER_LIST_VIEW,
            onSuccess: (e) => {
              showNotification('success', 'Add record has succeeded.')
              onSuccessCallback.current(e)
              onSetLoading(false)
            }
          }}
          renderButtonSection={(loading) => (
            <ButtonSection
              history={history}
              loading={loading}
              onSetLoading={onSetLoading}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default withFormState(AddPage)

function ButtonSection(props) {
  const { history, loading, onSetLoading } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const {
    formState: { isSubmitting },
    getValues
  } = useFormContext()
  const { getTranslatedText } = useContext(FdaPNTranslationContext)

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between'>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting || loading}
            onClick={() => setConfirmDialog(true)}
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                type='submit'
                color='primary'
                disabled={isSubmitting || loading}
              >
                {getTranslatedText('FDAPN', 'saveButton')}
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(pathMap.FDAPN_PRODUCTMASTER_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
