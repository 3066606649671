import React, { useEffect, useState, useContext, useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, useServices, constants } from 'cng-web-lib'
import { Grid, Divider, Typography, Tooltip } from '@material-ui/core'
import { FdaPNTranslationContext } from '../../contexts/FDAPNTranslationContext.js'
import { useLocation } from 'react-router-dom'
import CngSection from '../../../../components/cngcomponents/CngSection.js'
import PNDetailsTable from '../PNDetailsTable.js'

const {
    button: { CngButton, CngIconButton },
    form: {
        field: {
            CngCheckboxField,
            CngTextField,
            CngDateField,
            CngTimeField,
            CngCodeMasterAutocompleteField,
            CngCountryAutocompleteField,
            CngSelectField,
            CngUomAutocompleteField
        },
    },
    CngGridItem,
} = components

const {
    filter: { LIKE, EQUAL, IN }
} = constants

function PNDetailsSection(props) {
    const { disabled, shouldHideMap, user, blTypes, showNotification } = props
    const { getValues, setValue, reset, trigger } = useFormContext();
    const { securedSendRequest } = useServices()
    const { getTranslatedText } = useContext(FdaPNTranslationContext)
    const location = useLocation();
    const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false
    const { pga } = useWatch()

    function handleAddPNDetail(data) {
        const pgaDTO = [...getValues('pga'), data].map((pgaDTO, index) => {
            // if (Array.isArray(pgaDTO.party) && pgaDTO.party.length > 0) {
            //     pgaDTO.party = pgaDTO.party.map(prt => ({ ...prt, id: null, updatedBy: null, updatedDate: null, createdBy: null, createdDate: null, oriCreatedBy: null, oriUpdatedBy: null, oriSubmittedBy: null }));
            // }
            if (Array.isArray(pgaDTO.party) && pgaDTO.party.length > 0) {
                pgaDTO.party = pgaDTO.party.map((prt, index) => {
                    // if (prt.addParty) {
                    //     prt.addParty.id = null;
                    // }

                    if (prt.addParty) {
                        prt.addParty.id = null;
                        prt.addParty.updatedBy= null;
                        prt.addParty.updatedDate= null;
                        prt.addParty.createdBy= null;
                        prt.addParty.createdDate= null;
                        prt.addParty.oriCreatedBy= null;
                        prt.addParty.oriUpdatedBy= null;
                        prt.addParty.oriSubmittedBy= null;
                    }

                    return {
                        ...prt,
                        _id: index + 1,
                        id: null,
                        updatedBy: null,
                        updatedDate: null,
                        createdBy: null,
                        createdDate: null,
                        oriCreatedBy: null,
                        oriUpdatedBy: null,
                        oriSubmittedBy: null
                    };
                });
            }

            if (Array.isArray(pgaDTO.packaging) && pgaDTO.packaging.length > 0) {
                pgaDTO.packaging = pgaDTO.packaging.map((pck, index) => ({ ...pck, _id: index + 1, id: null, updatedBy: null, updatedDate: null, createdBy: null, createdDate: null, oriCreatedBy: null, oriUpdatedBy: null, oriSubmittedBy: null }));
            }

            if (Array.isArray(pgaDTO.containerInfo) && pgaDTO.containerInfo.length > 0) {
                pgaDTO.containerInfo = pgaDTO.containerInfo.map((cont, index) => ({ ...cont, _id: index + 1, id: null, updatedBy: null, updatedDate: null, createdBy: null, createdDate: null, oriCreatedBy: null, oriUpdatedBy: null, oriSubmittedBy: null }));
            }

            if (Array.isArray(pgaDTO.affirmation) && pgaDTO.affirmation.length > 0) {
                pgaDTO.affirmation = pgaDTO.affirmation.map((aff, index) => ({ ...aff, _id: index + 1, id: null, updatedBy: null, updatedDate: null, createdBy: null, createdDate: null, oriCreatedBy: null, oriUpdatedBy: null, oriSubmittedBy: null }));
            }

            if (Array.isArray(pgaDTO.productCond) && pgaDTO.productCond.length > 0) {
                pgaDTO.productCond = pgaDTO.productCond.map((prodC, index) => ({ ...prodC, _id: index + 1, id: null, updatedBy: null, updatedDate: null, createdBy: null, createdDate: null, oriCreatedBy: null, oriUpdatedBy: null, oriSubmittedBy: null }));
            }

            return { ...pgaDTO, _id: index + 1, pnConfirmationNumber: "", updatedBy: null, updatedDate: null, createdBy: null, createdDate: null, oriCreatedBy: null, oriUpdatedBy: null, oriSubmittedBy: null };
        });

        setValue('pga', pgaDTO, { shouldValidate: true })
    }

    function handleDeletePNDetail(data) {
        const pgaDTO = [...getValues('pga')].filter(
            (pgaDTO) => pgaDTO.id ? pgaDTO.id !== data.id : pgaDTO._id !== data._id
        )

        setValue('pga', pgaDTO, { shouldValidate: true })
    }

    function handleEditPNDetail(data) {
        const pgaDTO = [...getValues('pga')]
        const index = pgaDTO.findIndex(
            (pgaDTO) => pgaDTO.id ? pgaDTO.id === data.id : pgaDTO._id === data._id
        )

        pgaDTO[index] = data

        setValue('pga', pgaDTO, { shouldValidate: true })
    }

    function onAddPartyDetail(data) {
        const partyDTO = [...getValues('party'), data].map(
            (partyDTO, index) => ({ ...partyDTO, _id: index + 1 }))

        setValue('party', partyDTO, { shouldValidate: true })
    }

    function onDeletePartyDetail(data) {
        const partyDTO = [...getValues('party')].filter(
            (partyDTO) => partyDTO.id ? partyDTO.id !== data.id : partyDTO._id !== data._id
        )

        setValue('party', partyDTO, { shouldValidate: true })
    }

    function onEditPartyDetail(data) {
        const partyDTO = [...getValues('party')]
        const index = partyDTO.findIndex(
            (partyDTO) => partyDTO.id ? partyDTO.id === data.id : partyDTO._id === data._id
        )

        partyDTO[index] = data

        setValue('party', partyDTO, { shouldValidate: true })
    }

    return (
        <CngSection title={getTranslatedText('FDAPN', 'pgaTitle')} subheader={
            <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>*</Typography>
                Note: At least 1 prior notice details is required
            </Typography>
        }>
            <PNDetailsTable
                data={pga}
                onAddPNDetail={handleAddPNDetail}
                onDeletePNDetail={handleDeletePNDetail}
                onEditPNDetail={handleEditPNDetail}
                onAddPartyDetail={onAddPartyDetail}
                onDeletePartyDetail={onDeletePartyDetail}
                onEditPartyDetail={onEditPartyDetail}
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
                disabled={disabled}
            />
        </CngSection>
    )
}

export default PNDetailsSection