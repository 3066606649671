import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, useServices, constants } from 'cng-web-lib'
import { Grid, Divider, Typography } from '@material-ui/core'
import { FdaPNTranslationContext } from './../../contexts/FDAPNTranslationContext.js'
import { NaStateCodeAutoCompleteField, FDAPNArrivalPortAutocompleteField } from 'src/components/na'
import { useLocation } from 'react-router-dom'
import CngSection from '../../../../components/cngcomponents/CngSection'

const {
    button: { CngButton },
    form: {
        field: {
            CngCheckboxField,
            CngTextField,
            CngDateField,
            CngTimeField,
            CngCodeMasterAutocompleteField,
            CngCountryAutocompleteField,
            CngSelectField,
            CngUomAutocompleteField
        },
    },
    CngGridItem,
} = components

const {
    filter: { LIKE, EQUAL, IN }
} = constants

function HeaderDetailsSection(props) {
    const { disabled, shouldHideMap, user, blTypes, showNotification, isSuperParty } = props
    const { getValues, setValue, reset, trigger } = useFormContext();
    const { securedSendRequest } = useServices()
    const { getTranslatedText } = useContext(FdaPNTranslationContext)
    const location = useLocation();
    const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false
    const [isf10TemplateDialog, setIsf10TemplateDialog] = useState(false)
    const templateFlag = useWatch({ name: 'templateFlag' });
    const fromTemplate = useWatch({ name: 'fromTemplate' });
    const isEditTemplate = getValues('isEditTemplate')
    const { _id: _shipmentId, mot, referenceQualifier, arrivalLocation, billType } = useWatch()

    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        fdapn: null
    })

    return (
        <>
            <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap.templateFlag}>
                            <CngCheckboxField
                                label={
                                    <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                                        {getTranslatedText('ACE_ISF', 'templateFlag')}
                                    </Typography>
                                }
                                name='templateFlag'
                                disabled={disabled || isEditTemplate}
                                onChange={(e) => setValue('templateFlag', e.target.checked)}
                                size='small'
                                style={{ marginLeft: 4, marginRight: 4, padding: 8 }}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} lg shouldHide={shouldHideMap.templateName}>
                            <CngTextField
                                name='templateName'
                                inputProps={{ maxLength: 50 }}
                                placeholder={getTranslatedText('ACE_ISF', 'templateName')}
                                disabled={disabled || templateFlag === false || isEditTemplate}
                                onChange={(e) => { setValue('templateName', e.target.value.toUpperCase()) }}
                                onBlur={(e) => {
                                    if (e.target.value !== undefined) {
                                        setValue('templateName', e.target.value.toUpperCase().trim())
                                    }
                                }}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} lg='auto'>
                            <CngButton size='medium' color='secondary' onClick={() => setIsf10TemplateDialog(true)}
                                startIcon={<FontAwesomeIcon icon={['fal', 'money-check-edit']} />}>
                                Use template
                            </CngButton>
                        </CngGridItem>
                    </Grid>
                </Grid> */}
                <Grid item xs={12}>
                    <CngSection title={getTranslatedText('FDAPN', 'pnHeader')}
                        subheader={
                            <Typography color='textSecondary' variant='caption'>
                                <Typography variant='inherit' color='error'>*</Typography>
                                Mandatory fields
                            </Typography>
                        }
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.mot}>
                                        <CngCodeMasterAutocompleteField
                                            required
                                            name='mot'
                                            label={getTranslatedText('FDAPN', 'mot')}
                                            codeType='FDA_PN_MOT'
                                            size='small'
                                            disabled={disabled}
                                            key={mot}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={9}>
                                        <Grid container spacing={1}>
                                            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.referenceQualifier}>
                                                <CngCodeMasterAutocompleteField
                                                    required
                                                    name='referenceQualifier'
                                                    label={getTranslatedText('FDAPN', 'referenceQualifier')}
                                                    codeType='FDA_PN_REF_ID_CODE'
                                                    size='small'
                                                    disabled={disabled}
                                                    key={referenceQualifier}
                                                />
                                            </CngGridItem>
                                            <CngGridItem xs={12} sm={8} shouldHide={shouldHideMap.referenceIdNum}>
                                                <CngTextField
                                                    required={!isEditTemplate}
                                                    name="referenceIdNum"
                                                    inputProps={{ maxLength: 36 }}
                                                    label={getTranslatedText('FDAPN', 'referenceIdNum')}
                                                    disabled={getValues('status') === '1000' || isEditTemplate}
                                                    onChange={(e) => {
                                                        setValue("referenceIdNum", e.target.value.toUpperCase(), { shouldValidate: true })
                                                    }}
                                                    size='small'
                                                />
                                            </CngGridItem>
                                        </Grid>
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.anticipatedArrivalDate}>
                                        <CngDateField
                                            required
                                            name="anticipatedArrivalDate"
                                            label={getTranslatedText('FDAPN', 'anticipatedArrivalDate')}
                                            format="YYYY-MM-DD"
                                            disabled={disabled || getValues('status') === '1000'}
                                            size='small'
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.anticipatedArrivalTime}>
                                        <CngTimeField
                                            required
                                            name="anticipatedArrivalTime"
                                            label={getTranslatedText('FDAPN', 'anticipatedArrivalTime')}
                                            format="HH:mm"
                                            disabled={disabled}
                                            size='small'
                                            ampm={false}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.arrivalLocation}>
                                        <FDAPNArrivalPortAutocompleteField
                                            required
                                            name="arrivalLocation"
                                            label={getTranslatedText('FDAPN', 'arrivalLocation')}
                                            disabled={disabled}
                                            size='small'
                                            key={arrivalLocation}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.submitterOfEntry}>
                                        <CngCodeMasterAutocompleteField
                                            required
                                            name='submitterOfEntry'
                                            label={getTranslatedText('FDAPN', 'submitterOfEntry')}
                                            codeType='Y_OR_N'
                                            size='small'
                                            disabled={disabled}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.entryType}>
                                        <CngCodeMasterAutocompleteField
                                            required
                                            name='entryType'
                                            label={getTranslatedText('FDAPN', 'entryType')}
                                            codeType='FDA_PN_ENTRY_TYPE'
                                            size='small'
                                            disabled={disabled}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.billType}>
                                        <CngCodeMasterAutocompleteField
                                            name='billType'
                                            label={getTranslatedText('FDAPN', 'billType')}
                                            codeType='FDA_PN_BILL_TYPE'
                                            size='small'
                                            disabled={disabled}
                                            key={billType}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.carrierCode}>
                                        <CngTextField
                                            name="carrierCode"
                                            inputProps={{ maxLength: 4 }}
                                            label={getTranslatedText('FDAPN', 'carrierCode')}
                                            size='small'
                                            onChange={(e) => {
                                                setValue('carrierCode', e.target.value.toUpperCase())
                                            }}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.filerCode}>
                                        <CngTextField
                                            name="filerCode"
                                            label={getTranslatedText('FDAPN', 'filerCode')}
                                            inputProps={{ maxLength: 4 }}
                                            size='small'
                                            onChange={(e) => {
                                                setValue('filerCode', e.target.value.toUpperCase())
                                            }}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.envelopeNumber}>
                                        <CngTextField
                                            disabled={!isSuperParty}
                                            name="envelopeNumber"
                                            inputProps={{ maxLength: 12 }}
                                            label={getTranslatedText('FDAPN', 'envelopeNumber')}
                                            size='small'
                                            onChange={(e) => {
                                                setValue('envelopeNumber', e.target.value.toUpperCase())
                                            }}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.basketNumber}>
                                        <CngTextField
                                            name="basketNumber"
                                            label={getTranslatedText('FDAPN', 'basketNumber')}
                                            size='small'
                                            inputProps={{ maxLength: 20 }}
                                            onChange={(e) => {
                                                setValue('basketNumber', e.target.value.toUpperCase())
                                            }}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Grid>

                        </Grid>
                    </CngSection>
                </Grid>
            </Grid>
            {/* <TemplateSelectDialogWithOption
                isfTemplateDialog={isf10TemplateDialog}
                setIsfTemplateDialog={setIsf10TemplateDialog}
                setConfirmDialog={setConfirmDialog}
                showNotification={showNotification}
                setValue={setValue}
                reset={reset}
                trigger={trigger}
            /> */}
        </>
    )
}

export default HeaderDetailsSection