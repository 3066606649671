import makeValidationSchema from './MakeValidationSchema'
import { useTranslation, DateTimeFormatter, useServices } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import FdapnKeys from 'src/constants/locale/key/Fdapn'
import { Box, IconButton, Card, CardContent, Grid, Divider, Typography, InputAdornment, Tooltip } from '@material-ui/core'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NaAcePartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { NaCustomsOfficeAutocompleteField, NaSublocationWithoutCodeAutocompleteField, FDAPNArrivalPortAutocompleteField } from 'src/components/na'
import {
  components,
} from 'cng-web-lib'
import CngSection from 'src/components/cngcomponents/CngSection'
import PartyDetailsTable from './PartyDetailsTable'
import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import { NaStateCodeAutoCompleteField } from 'src/components/na'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import PackagingSection from './sections/PackagingSection'
import ContainerSection from './sections/ContainerSection'
import AffirmationSection from './sections/AffirmationSection'
import ProductCondSection from './sections/ProductCondSection'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import moment from 'moment-timezone'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCheckboxField,
      CngDateField,
      CngTimeField,
      CngCountryAutocompleteField,
    },
  },
  button: { CngIconButton },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  productId: "",
  commercialDesc: "",
  govAgencyProcessingCode: "",
  intendedUseCode: "",
  intendedUseDesc: "",
  productNumber: "",
  remarksCode: "",
  remarksText: "",
  lpcoNumber: "",
  lpcoIssuer: "",
  lpcoIssuerRegionalDesc: "",
  lpcoGovGeographicQualifier: "",
  lpcoIssuerLocation: "",
  anticipatedArrivalDate: moment().tz("Canada/Eastern").format('YYYY-MM-DD'),
  anticipatedArrivalTime: "23:59",
  arrivalLocation: "",
  totalQuantity: 0,
  uom: "",
  packageTrackingCode: "",
  packageTrackingNumber: "",
  commodityCharacteristicDesc: "",
  countryOfShipment: "",
  countryOfProduction: "",
  placeOfGrowth: "",
  countryOfEntryRefusal: "",
  baseUnitQuantity: 0,
  baseUnitUom: "",
  pnConfirmationNumber: "",
  basketNumber: "",
  saveProductFlag: "",
  productIdentification: "",
  productId: "",
  version: "",
  party: [],
  addParty: [],
  packaging: [],
  containerInfo: [],
  affirmation: [],
  productCond: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  shouldHideMap,
  index
}) {
  const { translate } = useTranslation(Namespace.FDAPN)
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const { getValues, setValue, reset, trigger, watch } = useFormContext();
  const saveProductFlag = useWatch({ name: 'saveProductFlag' });
  const [templateDialog, setTemplateDialog] = useState(false)
  const [pnDetailsExplanded, setpnDetailsExplanded] = useState(false)
  const { securedSendRequest } = useServices()
  const { govAgencyProcessingCode, arrivalLocation, countryOfShipment, countryOfProduction, countryOfEntryRefusal, party, containerInfo, intendedUseCode, intendedUseDesc, remarksCode, remarksText, lpcoIssuerLocation, lpcoGovGeographicQualifier, affirmation, productCond, lpcoIssuer, lpcoIssuerRegionalDesc, lpcoNumber } = useWatch()
  const [user, setUser] = useState([]);
  const packaging = watch('packaging')

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `pga.${index}.${field}` : field
  }, [])

  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  useEffect(() => {
    if (affirmation.length > 0 || productCond.length > 0 || intendedUseCode || intendedUseDesc || lpcoIssuer || lpcoIssuerLocation || lpcoIssuerRegionalDesc || remarksCode || remarksText || lpcoNumber || lpcoGovGeographicQualifier) {
      setpnDetailsExplanded(true);
    }
  }, []);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.productId}>
            <CngTextField
              required
              name="productId"
              label={getTranslatedText('FDAPN', 'pgaProductId')}
              onChange={(e) => {
                setValue('productId', e.target.value.toUpperCase())
              }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.productNumber}>
            <CngTextField
              required
              name={typeof index === 'number' ? `pga.${index}.productNumber` : 'productNumber'}
              label={getTranslatedText('FDAPN', 'pgaProductNumber')}
              onChange={(e) => {
                setValue('productNumber', e.target.value.toUpperCase())
              }}
              inputProps={{ maxLength: 7 }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.anticipatedArrivalDate}>
            <CngDateField
              required
              name="anticipatedArrivalDate"
              label={getTranslatedText('FDAPN', 'anticipatedArrivalDate')}
              format="YYYY-MM-DD"
              disabled={disabled || getValues('status') === '1000'}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.anticipatedArrivalTime}>
            <CngTimeField
              required
              name="anticipatedArrivalTime"
              label={getTranslatedText('FDAPN', 'anticipatedArrivalTime')}
              format="HH:mm"
              disabled={disabled}
              size='small'
              ampm={false}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.govAgencyProcessingCode}>
            <CngCodeMasterAutocompleteField
              required
              name={typeof index === 'number' ? `pga.${index}.govAgencyProcessingCode` : 'govAgencyProcessingCode'}
              label={getTranslatedText('FDAPN', 'pgaGovAgencyProcessingCode')}
              codeType='FDA_PN_GOV_AGENCY_PROCESSING'
              size='small'
              disabled={disabled}
              key={govAgencyProcessingCode}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.commercialDesc}>
            <CngTextField
              required
              name={typeof index === 'number' ? `pga.${index}.commercialDesc` : 'commercialDesc'}
              label={getTranslatedText('FDAPN', 'pgaCommercialDesc')}
              inputProps={{ maxLength: 70 }}
              onChange={(e) => {
                setValue('commercialDesc', e.target.value.toUpperCase())
              }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.commodityCharacteristicDesc}>
            <CngTextField
              required
              name={typeof index === 'number' ? `pga.${index}.commodityCharacteristicDesc` : 'commodityCharacteristicDesc'}
              label={getTranslatedText('FDAPN', 'pgaCommodityCharacteristicDesc')}
              inputProps={{ maxLength: 57 }}
              onChange={(e) => {
                setValue('commodityCharacteristicDesc', e.target.value.toUpperCase())
              }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.arrivalLocation}>
            <FDAPNArrivalPortAutocompleteField
              required
              name={typeof index === 'number' ? `pga.${index}.arrivalLocation` : 'arrivalLocation'}
              label={getTranslatedText('FDAPN', 'arrivalLocation')}
              disabled={disabled}
              size='small'
              key={arrivalLocation}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.packageTrackingCode}>
            <CngTextField
              name={typeof index === 'number' ? `pga.${index}.packageTrackingCode` : 'packageTrackingCode'}
              label={getTranslatedText('FDAPN', 'pgaPackageTrackingCode')}
              onChange={(e) => {
                setValue('packageTrackingCode', e.target.value.toUpperCase())
              }}
              inputProps={{ maxLength: 4 }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.packageTrackingNumber}>
            <CngTextField
              name={typeof index === 'number' ? `pga.${index}.packageTrackingNumber` : 'packageTrackingNumber'}
              label={getTranslatedText('FDAPN', 'pgaPackageTrackingNumber')}
              onChange={(e) => {
                setValue('packageTrackingNumber', e.target.value.toUpperCase())
              }}
              inputProps={{ maxLength: 50 }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} md={4} lg={4} shouldHide={shouldHideMap?.countryOfProduction}>
            <CngCountryAutocompleteField
              required
              name={typeof index === 'number' ? `pga.${index}.countryOfProduction` : 'countryOfProduction'}
              label={getTranslatedText('FDAPN', 'pgaCountryOfProduction')}
              disabled={disabled}
              size='small'
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
              key={countryOfProduction}
            />
          </CngGridItem>
          <CngGridItem xs={12} md={4} lg={4} shouldHide={shouldHideMap?.countryOfShipment}>
            <CngCountryAutocompleteField
              name={typeof index === 'number' ? `pga.${index}.countryOfShipment` : 'countryOfShipment'}
              label={getTranslatedText('FDAPN', 'pgaCountryOfShipment')}
              disabled={disabled}
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
              size='small'
              key={countryOfShipment}
            />
          </CngGridItem>
          <CngGridItem xs={12} md={4} lg={4} shouldHide={shouldHideMap?.countryOfEntryRefusal}>
            <CngCountryAutocompleteField
              name={typeof index === 'number' ? `pga.${index}.countryOfEntryRefusal` : 'countryOfEntryRefusal'}
              inputProps={{ maxLength: 2 }}
              label={getTranslatedText('FDAPN', 'pgaCountryOfEntryRefusal')}
              disabled={disabled}
              key={countryOfEntryRefusal}
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.pnConfirmationNumber}>
            <CngTextField
              disabled={true}
              name={typeof index === 'number' ? `pga.${index}.pnConfirmationNumber` : 'pnConfirmationNumber'}
              label={getTranslatedText('FDAPN', 'pnConfirmationNumber')}
              onChange={(e) => {
                setValue('pnConfirmationNumber', e.target.value.toUpperCase())
              }}
              size='small'
            />
          </CngGridItem>

          <CngGridItem xs={12} lg={12}>
            <CngSection title={getTranslatedText('FDAPN', 'partyTitle')}>
              <PartyDetailsTable
                data={party}
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
                disabled={disabled}
              />
            </CngSection>
          </CngGridItem>

          <Grid item xs={12} sm={6}>
            <PackagingSection
              // packaging={packaging}
              showNotification={showNotification}
            // title='Driver / Crew / Passenger'
            // crewSectionHelper={translatedTextsObject.crewSectionHelper}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ContainerSection
              // packaging={packaging}
              showNotification={showNotification}
            // title='Driver / Crew / Passenger'
            // crewSectionHelper={translatedTextsObject.crewSectionHelper}
            />
          </Grid>

          <Grid item xs={12}>
            <CollapsibleSection
              defaultExpanded={pnDetailsExplanded}
              title={getTranslatedText('FDAPN', 'pgaAdditionalPNDetails')}
            >
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.intendedUseCode}>
                  <CngCodeMasterAutocompleteField
                    name={typeof index === 'number' ? `pga.${index}.intendedUseCode` : 'intendedUseCode'}
                    label={getTranslatedText('FDAPN', 'pgaIntendedUseCode')}
                    codeType='FDA_PN_INTENDED_USE'
                    size='small'
                    disabled={disabled}
                    key={intendedUseCode}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.intendedUseDesc}>
                  <CngTextField
                    name={typeof index === 'number' ? `pga.${index}.intendedUseDesc` : 'intendedUseDesc'}
                    label={getTranslatedText('FDAPN', 'pgaIntendedUseDesc')}
                    onChange={(e) => {
                      setValue('intendedUseDesc', e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.remarksCode}>
                  <CngCodeMasterAutocompleteField
                    name={typeof index === 'number' ? `pga.${index}.remarksCode` : 'remarksCode'}
                    label={getTranslatedText('FDAPN', 'pgaRemarksCode')}
                    codeType='FDA_PN_REMARKS_CODE'
                    size='small'
                    disabled={disabled}
                    key={remarksCode}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.remarksText}>
                  <CngTextField
                    name={typeof index === 'number' ? `pga.${index}.remarksText` : 'remarksText'}
                    label={getTranslatedText('FDAPN', 'pgaRemarksText')}
                    inputProps={{ maxLength: 35 }}
                    onChange={(e) => {
                      setValue('remarksText', e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.lpcoIssuer}>
                  <CngTextField
                    name={typeof index === 'number' ? `pga.${index}.lpcoIssuer` : 'lpcoIssuer'}
                    label={getTranslatedText('FDAPN', 'pgaLpcoIssuer')}
                    inputProps={{ maxLength: 35 }}
                    onChange={(e) => {
                      setValue('lpcoIssuer', e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={1} shouldHide={shouldHideMap.lpcoGovGeographicQualifier}>
                  <CngCodeMasterAutocompleteField
                    name={typeof index === 'number' ? `pga.${index}.lpcoGovGeographicQualifier` : 'lpcoGovGeographicQualifier'}
                    label={getTranslatedText('FDAPN', 'pgaLpcoGovGeographicQualifier')}
                    codeType='FDA_PN_LPCO_LOCATION_ID'
                    size='small'
                    disabled={disabled}
                    key={lpcoGovGeographicQualifier}
                    onChange={(e) => {
                      setValue('lpcoIssuerLocation', '')
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={2} shouldHide={shouldHideMap.lpcoIssuerLocation}>
                  {lpcoGovGeographicQualifier !== 'ISO' && <NaStateCodeAutoCompleteField
                    name={typeof index === 'number' ? `pga.${index}.lpcoIssuerLocation` : 'lpcoIssuerLocation'}
                    label={getTranslatedText('FDAPN', 'pgaLpcoIssuerLocation')}
                    disabled={disabled}
                    countryCode={lpcoGovGeographicQualifier === 'PR' ? 'CA' : lpcoGovGeographicQualifier === 'MS' ? 'MX' : lpcoGovGeographicQualifier}
                    key={lpcoIssuerLocation + lpcoGovGeographicQualifier}
                    size='small'
                  />}
                  {lpcoGovGeographicQualifier === 'ISO' && <CngCountryAutocompleteField
                    name={typeof index === 'number' ? `pga.${index}.lpcoIssuerLocation` : 'lpcoIssuerLocation'}
                    label={getTranslatedText('FDAPN', 'pgaLpcoIssuerLocation')}
                    disabled={disabled}
                    key={lpcoIssuerLocation + lpcoGovGeographicQualifier}
                    lookupProps={{
                      label: (record) => `${record.code};${record.descriptionEn}`
                    }}
                    size='small'
                  />} 
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.lpcoIssuerRegionalDesc}>
                  <CngTextField
                    name={typeof index === 'number' ? `pga.${index}.lpcoIssuerRegionalDesc` : 'lpcoIssuerRegionalDesc'}
                    label={getTranslatedText('FDAPN', 'pgaLpcoIssuerRegionalDesc')}
                    onChange={(e) => {
                      setValue('lpcoIssuerRegionalDesc', e.target.value.toUpperCase())
                    }}
                    inputProps={{ maxLength: 25 }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.lpcoNumber}>
                  <CngTextField
                    name={typeof index === 'number' ? `pga.${index}.lpcoNumber` : 'lpcoNumber'}
                    label={getTranslatedText('FDAPN', 'pgaLpcoNumber')}
                    onChange={(e) => {
                      setValue('lpcoNumber', e.target.value.toUpperCase())
                    }}
                    inputProps={{ maxLength: 33 }}
                    size='small'
                  />
                </CngGridItem>

                <Grid item xs={12} sm={6}>
                  <AffirmationSection
                    showNotification={showNotification}
                  // title='Driver / Crew / Passenger'
                  // crewSectionHelper={translatedTextsObject.crewSectionHelper}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ProductCondSection
                    showNotification={showNotification}
                  // title='Driver / Crew / Passenger'
                  // crewSectionHelper={translatedTextsObject.crewSectionHelper}
                  />
                </Grid>
              </Grid>
            </CollapsibleSection>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

function toClientDataFormat(serverData) {
  // let localData = DataFlattener.parse(serverData);
  // localData.anticipatedArrivalDate = DateTimeFormatter.toClientDate(
  //   localData.anticipatedArrivalDate
  // );
  // localData.responseDatetime = DateTimeFormatter.toClientDate(
  //   localData.responseDatetime
  // );
  // localData.createdDate = DateTimeFormatter.toClientDate(
  //   localData.createdDate
  // );
  // localData.updatedDate = DateTimeFormatter.toClientDate(
  //   localData.updatedDate
  // );
  // localData.submittedDate = DateTimeFormatter.toClientDate(
  //   localData.submittedDate
  // );
  // console.log("localData.pga: " + JSON.string(localData.pga));
  // localData.pga.forEach((datum) => {
  //   datum.anticipatedArrivalDate = DateTimeFormatter.toClientDate(
  //     datum.anticipatedArrivalDate
  //   );
  // });
  serverData.anticipatedArrivalDate = moment(serverData.anticipatedArrivalDate).format('YYYY-MM-DD');
  // serverData.anticipatedArrivalTime = moment(serverData.anticipatedArrivalTime).format('HH:mm');
  
  serverData.anticipatedArrivalDate = DateTimeFormatter.toClientDate(
    serverData.anticipatedArrivalDate
  );
  return serverData;
}

function toServerDataFormat(localData) {

  localData.anticipatedArrivalTime = localData.anticipatedArrivalTime && localData.anticipatedArrivalTime.replace(':', '');

  // localData.anticipatedArrivalDate = DateTimeFormatter.toServerDate(
  //   localData.anticipatedArrivalDate
  // );
  // localData.responseDatetime = DateTimeFormatter.toServerDate(
  //   localData.responseDatetime
  // );
  // localData.createdDate = DateTimeFormatter.toServerDate(
  //   localData.createdDate
  // );
  // localData.updatedDate = DateTimeFormatter.toServerDate(
  //   localData.updatedDate
  // );
  // localData.submittedDate = DateTimeFormatter.toServerDate(
  //   localData.submittedDate
  // );
  // localData.pga.forEach((datum) => {
  //   datum.anticipatedArrivalDate = DateTimeFormatter.toServerDate(
  //     datum.anticipatedArrivalDate
  //   );
  //   datum.anticipatedArrivalTime = datum.anticipatedArrivalTime && datum.anticipatedArrivalTime.replace(':', '');
  // });
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
