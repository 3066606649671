import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    referenceQualifier: Yup.string().required(requiredMessage).nullable(),
    referenceIdNum: Yup.string().required(requiredMessage).nullable(),
    anticipatedArrivalDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    anticipatedArrivalTime: Yup.string().required(requiredMessage).nullable(),
    arrivalLocation: Yup.string().required(requiredMessage).nullable(),
    filerCode: Yup.string().nullable().matches("^[a-zA-Z0-9\-]*$", "Should be a valid 4 character/digits Filler Code"),
    billType: Yup.string().nullable(),
    carrierCode: Yup.string().nullable().matches("^[a-zA-Z0-9\-]*$", "Should be a valid 4 character/digits Carrier SCAC"),
    entryType: Yup.string().required(requiredMessage).nullable(),
    mot: Yup.string().required(requiredMessage).nullable(),
    envelopeNumber: Yup.string().nullable(),
    actionCode: Yup.string().required(requiredMessage).nullable(),
    submitterOfEntry: Yup.string().required(requiredMessage).nullable(),
    basketNumber: Yup.string().nullable(),
    // ultimateConsignee: Yup.string(),
    // bol: Yup.array(),
    // pga: Yup.array(),
    // party: Yup.array(),
    // addParty: Yup.array(),
    // packaging: Yup.array(),
    // containerInfo: Yup.array(),
    // affirmation: Yup.array(),
    // productCond: Yup.array(),
    // pga: Yup.array().of(),
    // pga: Yup.array().of(Yup.object({
    //   packaging: Yup.array().of(Yup.object({
    //     packagingQualifier: Yup.string().nullable(),
    //     quantity: Yup.string().nullable().required(requiredMessage).matches("^[0-9\-]*$", "Should be a valid digits Quantity"),
    //     uom: Yup.string().required(requiredMessage).nullable(),
    //   })).nullable()
    // })).nullable(),
    pga: Yup.array(),
    packaging: Yup.array(),
  })
}

export default makeValidationSchema
