import React, { useRef, useEffect, useContext } from 'react'
import { components } from 'cng-web-lib'
import CngSection from '../../../../components/cngcomponents/CngSection'
import { Box, Grid, Tooltip, Divider } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import _ from 'lodash'
import AddPartyFormProperties from '../AddPartyFormProperties'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { FdaPNTranslationContext } from '../../contexts/FDAPNTranslationContext.js'

const {
  button: { CngIconButton },
  form: {
    field: {
      CngSelectField,
      CngTextField,
      CngUomAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem
} = components

const { initialValues } = AddPartyFormProperties.formikProps

function AddPartyDetailSection(props) {
  const addParty = useWatch({ name: 'addParty' })
  const { showNotification, title, crewSectionHelper, shouldHideMap, index } = props

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <AddPartyFormProperties.Fields showNotification={showNotification} shouldHideMap={shouldHideMap} index={index}/>
      </Grid>
    </Grid>
  )
}

export default AddPartyDetailSection
