import React, { useRef, useEffect, useContext } from 'react'
import { components } from 'cng-web-lib'
import CngSection from '../../../../components/cngcomponents/CngSection'
import { Box, Grid, Tooltip, Divider } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import _ from 'lodash'
import ProductCondFormProperties from '../ProductCondFormProperties'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { FdaPNTranslationContext } from '../../contexts/FDAPNTranslationContext.js'
import ProductCondEntry from './../ProductCondEntry'

const {
  button: { CngIconButton },
  form: {
    field: {
      CngSelectField,
      CngTextField,
      CngUomAutocompleteField
    },
  },
  CngGridItem
} = components

const { initialValues } = ProductCondFormProperties.formikProps

function ProductCondSection(props) {
  const productCond = useWatch({ name: 'productCond' })
  const { showNotification, title, crewSectionHelper, shouldHideMap } = props

  const { getValues, setValue } = useFormContext()
  const lastIndex = useRef(productCond.length)
  const { getTranslatedText } = useContext(FdaPNTranslationContext)

  function handleAddProdCond(data) {
    const prodCondList = [...getValues('productCond'), data].map(
      (prodCond, index) => ({ ...prodCond, _id: index + 1 })
    )
    setValue('productCond', prodCondList)
  }

  function handleDeleteProductCond(data) {
    const clonedProdCondList = [...getValues('productCond')].filter(
      (prodCond) => prodCond.id ? prodCond.id !== data.id : prodCond._id !== data._id
    )
    const prodCondList = clonedProdCondList.map(
      (prodCond, index) => ({ ...prodCond, _id: index + 1 })
    )
    setValue('productCond', prodCondList)
  }

  return (
    <CngSection title={getTranslatedText('FDAPN', 'productCondTitle')} helperText={crewSectionHelper} alignItems='flex-end'>
      <Box display='flex' flexDirection='column' style={{ gap: 8 }}>
        {productCond.map((prodCondDTO, index) => (
          <React.Fragment key={prodCondDTO._id || prodCondDTO.id || index}>
            <Grid item xs={12}>
              <ProductCondEntry
                index={index}
                isLast={productCond.length === 1}
                onAddProdCond={handleAddProdCond}
                onDeleteProdCond={handleDeleteProductCond}
                prodCondDTO={prodCondDTO}
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
              />
            </Grid>
            {productCond.length !== index + 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>
      {productCond.length === 0 && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs='auto'>
              <Tooltip placement='bottom' title='Add new'>
                <span>
                  <CngIconButton
                    icon={['fal', 'plus']}
                    onClick={() => handleAddProdCond(initialValues)}
                    size='small'
                    type='outlined'
                  />
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )}
    </CngSection>
  )
}

export default ProductCondSection
