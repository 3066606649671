import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, constants } from 'cng-web-lib'
import { Box, Grid } from '@material-ui/core'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngField from 'src/components/cngcomponents/CngField'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext'
import PartyFormProperties from './PartyFormProperties'

const {
  button: { CngButton, CngPrimaryButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function PNDetailsDialog(props) {
  const {
    onAddPartyDetailDTO,
    onEditPartyDetailDTO,
    isView,
    onClose,
    open,
    showNotification,
    partyDTO
  } = props

  const { initialValues, makeValidationSchema } = PartyFormProperties.formikProps
  const { getTranslatedText } = useContext(FdaPNTranslationContext)

  return (
    <CngDialog
      dialogContent={
        isView && partyDTO ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CngSection title={getTranslatedText('FDAPN', 'partyTittle')}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyType')}>
                      {partyDTO.partyType}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyIdType')}>
                      {partyDTO.partyIdType}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyIdTypeValue')}>
                      {partyDTO.partyIdTypeValue}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyName')}>
                      {partyDTO.name}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyApartmentSuiteNumber')}>
                      {partyDTO.apartmentSuiteNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyAddress1')}>
                      {partyDTO.address1}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyAddress2')}>
                      {partyDTO.address2}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyCountryCode')}>
                      {partyDTO.countryCode}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyStateProvince')}>
                      {partyDTO.stateProvince}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyCityName')}>
                      {partyDTO.cityName}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyPostalCode')}>
                      {partyDTO.postalCode}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyContactFirstName')}>
                      {partyDTO.contactFirstName}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyContactPhoneNumber')}>
                      {partyDTO.contactPhoneNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('FDAPN', 'partyEmail')}>
                      {partyDTO.email}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
          </Grid>
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: partyDTO || initialValues,
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (partyDTO) {
                  onEditPartyDetailDTO(data)
                } else {
                  onAddPartyDetailDTO(data)
                }

                onClose()
              }
            }}
            formState={FormState.COMPLETED}
            renderBodySection={(labelMap, shouldHideMap) => (
              <Box padding={1}>
                <PartyFormProperties.Fields
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                />
              </Box>
            )}
            innerForm={true}
            renderButtonSection={() => (
              <Box display='flex' justifyContent='flex-end'>
                <CngPrimaryButton type='submit'>
                  {partyDTO
                    ? getTranslatedText('FDAPN', 'updateButton')
                    : getTranslatedText('FDAPN', 'saveButton')}
                </CngPrimaryButton>
              </Box>
            )}
          />
        )
      }
      dialogAction={
        isView ? <CngButton onClick={onClose}>OK</CngButton> : null
      }
      dialogTitle={getTranslatedText('FDAPN', 'partyTitle')}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default PNDetailsDialog
