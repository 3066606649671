import React, { useEffect, useRef, useState, useContext, useMemo } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import FdapnKeys from 'src/constants/locale/key/Fdapn'
import { Grid, Card, CardContent } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
  useServices, constants
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import { useFormContext, useWatch } from "react-hook-form";
import HeaderDetailsSection from './sections/HeaderDetailsSection'
import BillOfLadingSection from './sections/BillOfLadingSection'
import PNDetailsSection from './sections/PNDetailsSection'
import ActionCodeSection from './sections/ActionCodeSection'
import CngSection from 'src/components/cngcomponents/CngSection'
import moment from 'moment-timezone'
import { isCompositeComponent } from 'react-dom/test-utils'
import { useLocation } from 'react-router-dom'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
    },
  },
  table: {
    CngLocalModeDialogFormTable,
    DateRangeFilter: CngDateRangeFilter,
    useFetchCodeMaintenanceLookup
  },
  CngGridItem,
} = components

const {
  CodeMaintenanceType,
  filter: { EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  acctId: "",
  corpid: "",
  referenceQualifier: "",
  referenceIdNum: "",
  pnConfirmationNumber: "",
  anticipatedArrivalDate: moment().tz("Canada/Eastern").format('YYYY-MM-DD'),
  anticipatedArrivalTime: "23:59",
  arrivalLocation: "",
  filerCode: "",
  billType: "",
  carrierCode: "",
  entryType: "",
  mot: "",
  envelopeNumber: "",
  actionCode: "A",
  status: "",
  submitterOfEntry: "",
  responseDatetime: "",
  sn: "",
  basketNumber: "",
  ultimateConsignee: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  submittedBy: "",
  submittedDate: "",
  version: "",
  partyId: "",
  submissionFlow: "",
  bol: [],
  pga: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.FDAPN)
  const [user, setUser] = useState([]);
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const { securedSendRequest } = useServices()
  const { getValues, setValue, watch } = useFormContext();
  const location = useLocation();
  const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false
  const [isSuperParty, setIsSuperParty] = useState(false)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }
      ])
    ]).then(([billingSuperParty]) => {
      if (Object.keys(billingSuperParty).includes(GetLoggedInUserPartyName())) {

        setIsSuperParty(true)
      }
    })
  }, [])

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  const memoedHeaderDetailsSection = useMemo(() => (
    <HeaderDetailsSection disabled={disabled} shouldHideMap={shouldHideMap}
      user={user} showNotification={showNotification} isSuperParty={isSuperParty}/>
  ), [user])

  const memoedBOLDetailsSection = useMemo(() => (
    <BillOfLadingSection disabled={disabled} shouldHideMap={shouldHideMap}
      user={user} showNotification={showNotification} />
  ), [])

  const memoedPNDetailsSection = useMemo(() => (
    <PNDetailsSection disabled={disabled} shouldHideMap={shouldHideMap}
      user={user} showNotification={showNotification} />
  ), [])

  const memoedActionCodeSection = useMemo(() => (
    <ActionCodeSection disabled={disabled} shouldHideMap={shouldHideMap}
      user={user} showNotification={showNotification} />
  ), [])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngSection title={getTranslatedText('FDAPN', 'title')}>
          {memoedHeaderDetailsSection}
          &nbsp;
          {memoedBOLDetailsSection}
          &nbsp;
          {memoedPNDetailsSection}
          &nbsp;
          {memoedActionCodeSection}
        </CngSection>
      </Grid>
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  serverData.anticipatedArrivalDate = DateTimeFormatter.toClientDate(
    serverData.anticipatedArrivalDate
  );
  serverData.pga && serverData.pga.forEach((datum) => {
    datum.anticipatedArrivalDate = DateTimeFormatter.toClientDate(
      datum.anticipatedArrivalDate
    );
  });
  return serverData;
}

function toServerDataFormat(localData) {

  localData.anticipatedArrivalTime = localData.anticipatedArrivalTime && localData.anticipatedArrivalTime.replace(':', '');

  localData.anticipatedArrivalDate = DateTimeFormatter.toServerDate(
    localData.anticipatedArrivalDate
  );
  localData.responseDatetime = DateTimeFormatter.toServerDate(
    localData.responseDatetime
  );
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );
  localData.submittedDate = DateTimeFormatter.toServerDate(
    localData.submittedDate
  );
  localData.pga.forEach((datum) => {
    datum.anticipatedArrivalDate = DateTimeFormatter.toServerDate(
      datum.anticipatedArrivalDate
    );
    datum.anticipatedArrivalTime = datum.anticipatedArrivalTime && datum.anticipatedArrivalTime.replace(':', '');
  });
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
