import FdapnApiUrls from 'src/apiUrls/FdapnApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { components, constants, DataFlattener, useTranslation } from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import pathMap from 'src/paths/pathMap'
import axios from 'axios'
import ChargeTypeDialog from '../../common/ChargeTypeDialog'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import ConfirmDialog from '../../common/ConfirmDialog'
import { useFormContext, useWatch } from 'react-hook-form';
import ViewLogsHistory from "./ViewLogsHistory";
import StatusNotificationListPage from "./StatusNotificationListPage"
import withFormState from './../../../components/aciacehighway/hocs/withFormState'

const {
  button: { CngButton },
  form: { CngEditForm },
  CngTabs,
  table: { useFetchCodeMaintenanceLookup },
} = components

const { filter: { EQUAL } } = constants

function EditPage(props) {
  const { history, loading, location, onSetLoading, showNotification } = props
  const { id } = useParams()
  const { state } = location
  const serverDataRef = useRef()
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })
  const [isSuperParty, setSuperParty] = useState(false);
  const [chargeTypes, setChargeTypes] = useState([])
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const onSuccessCallback = useRef(() => { })

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  function preSubmit() {
    const values = { ...serverDataRef.current, id }

    if (isSuperParty) {
      setSubmitDialog({ open: true, items: values })
    } else {
      values.chargeAs = 'REGULAR'
      serverDataRef.current = values
      handleSubmitFDAPN()
    }
  }

  function handleSubmitFDAPN() {
    console.log("serverDataRef: " + JSON.stringify(serverDataRef));
    onSetLoading(true)

    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      serverDataRef.current.fileForUserId = fileForUserDetails.fileForUserId;
      serverDataRef.current.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      serverDataRef.current.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    instance
      .post(
        FdapnApiUrls.SUBMIT,
        DataFlattener.unflatten(serverDataRef.current)
      )
      .then((result) => {
        var returnData = result.data
        var errorMessages = returnData.errorMessages
        if (
          result.status == 200 &&
          returnData != null &&
          errorMessages == null
        ) {
          showNotification('success', getTranslatedText('FDAPN', 'submitSuccessMsg'))
          history.push(pathMap.FDAPN_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => onSetLoading(false))
  }
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs activeTabIndex={state?.active ? state.active : 0}
          headerColor='primary'
          tabs={[
            {
              tabName: getTranslatedText('FDAPN', 'title'),
              tabContent: (
                <CngEditForm
                  fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                  showNotification={showNotification}
                  renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
                    <FormProperties.Fields
                      disabled={false}
                      showNotification={showNotification}
                      shouldHideMap={shouldHideMap}
                    />
                  )}
                  formikProps={FormProperties.formikProps}
                  toClientDataFormat={FormProperties.toClientDataFormat}
                  toServerDataFormat={FormProperties.toServerDataFormat}
                  fetch={{
                    url: FdapnApiUrls.GET
                  }}
                  update={{
                    url: FdapnApiUrls.PUT,
                    // onSuccess: (e) => {
                    //   if (e && e.referenceQualifier === "ENT") {
                    //     showNotification('success', 'Entry number has been updated: ' + e.referenceIdNum);
                    //   }
                    //   showNotification('success', 'Update record has succeeded.')
                    //   onSuccessCallback.current(e)
                    //   onSetLoading(false)
                    // }
                  }}
                  id={id}
                  renderButtonSection={(_, loading) => (
                    <ButtonSection
                      history={history}
                      onSave={() => {
                        onSuccessCallback.current = (e) => {
                          onSetLoading(false)
                        }
                      }}
                      onSubmitFDAPN={(data) => {
                        serverDataRef.current = data
                        preSubmit()
                      }}
                      loading={loading}
                      onSetLoading={onSetLoading}
                    />
                  )}
                />
              )
            },
            {
              tabName: getTranslatedText('FDAPN', 'statusNotification'),
              tabContent: (
                <StatusNotificationListPage
                  showNotification={showNotification}
                  pnHeaderId={id}
                  onSetLoading={onSetLoading}
                />
              )
            },
            {
              tabName: getTranslatedText('FDAPN', 'responses'),
              tabContent: (
                <ViewLogsHistory
                  pnHeaderId={id}
                  history={history} 
                />
              )
            }
          ]}
        />
      </Grid>
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(data) => {
          serverDataRef.current = data
          handleSubmitFDAPN()
        }}
        open={submitDialog.open}
        items={submitDialog.items}
      />
    </Grid>
  )
}

export default withFormState(EditPage)

function ButtonSection(props) {
  const { history, loading, onSave, onSubmitFDAPN, onSetLoading } = props
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { formState: { isSubmitting }, getValues, trigger } = useFormContext()
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const editTemplate = getValues() ? getValues().isEditTemplate : false;
  const serverDataRef = useRef()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between'>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting || loading}
            onClick={() => setConfirmDialog(true)}
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                type='submit'
                color='primary'
                disabled={isSubmitting || loading}
                shouldShowProgress={loading}
                onClick={onSave}
              >
                {getTranslatedText('FDAPN', 'updateButton')}
              </CngButton>
            </Grid>
            {!editTemplate && <>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  disabled={isSubmitting || loading}
                  shouldShowProgress={loading}
                  onClick={async () => {
                    let result = await trigger()
                    if (result) {
                      onSubmitFDAPN(getValues())
                    }
                  }}
                >
                  {getTranslatedText('FDAPN', 'submitButton')}
                </CngButton>
              </Grid>
            </>}
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(pathMap.FDAPN_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
