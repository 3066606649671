import React, { useRef, useEffect, useContext } from 'react'
import { components } from 'cng-web-lib'
import CngSection from '../../../../components/cngcomponents/CngSection'
import { Box, Grid, Tooltip, Divider, Typography } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import _ from 'lodash'
import AffirmationFormProperties from '../AffirmationFormProperties'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { FdaPNTranslationContext } from '../../contexts/FDAPNTranslationContext.js'
import AffirmationEntry from './../AffirmationEntry'

const {
  button: { CngIconButton },
  form: {
    field: {
      CngSelectField,
      CngTextField,
      CngUomAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const { initialValues } = AffirmationFormProperties.formikProps

function AffirmationSection(props) {
  const affirmation = useWatch({ name: 'affirmation' })
  const { showNotification, title, crewSectionHelper, shouldHideMap } = props

  const { getValues, setValue } = useFormContext()
  const lastIndex = useRef(affirmation.length)
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const { complianceAffirmationCode } = useWatch()

  useEffect(() => {
    if (affirmation.length === 0) {
      const { initialValues } = AffirmationFormProperties.formikProps
      setValue('affirmation', [{ ...initialValues, _id: 1 }])
    }
  }, [])

  function handleAddAffirmation(data) {
    const affirmationList = [...getValues('affirmation'), data].map(
      (affirmationDTO, index) => ({ ...affirmationDTO, _id: index + 1 })
    )
    setValue('affirmation', affirmationList)
  }

  function handleDeleteAffirmation(data) {
    const clonedAffirmationList = [...getValues('affirmation')].filter(
      (affirmationDTO) => affirmationDTO.id ? affirmationDTO.id !== data.id : affirmationDTO._id !== data._id
    )
    const affirmationList = clonedAffirmationList.map(
      (affirmationDTO, index) => ({ ...affirmationDTO, _id: index + 1 })
    )
    setValue('affirmation', affirmationList)
  }

  return (
    <CngSection title={getTranslatedText('FDAPN', 'affirmationTitle')} helperText={crewSectionHelper} alignItems='flex-end' subheader={
      <Typography color='textSecondary' variant='caption'>
        <Typography variant='inherit' color='error'>*</Typography>
        Note: At least 1 affirmation of compliance details is required
      </Typography>
    }>
      <Box display='flex' flexDirection='column' style={{ gap: 8 }}>
        {affirmation.map((affirmationDTO, index) => (
          <React.Fragment key={affirmationDTO._id || affirmationDTO.id || index}>
            <Grid item xs={12}>
              <AffirmationEntry
                index={index}
                isLast={affirmation.length === 1}
                onAddAffirmation={handleAddAffirmation}
                onDeleteAffirmation={handleDeleteAffirmation}
                affirmationDTO={affirmationDTO}
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
              />
            </Grid>
            {affirmation.length !== index + 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>
      {affirmation.length === 0 && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs='auto'>
              <Tooltip placement='bottom' title='Add new'>
                <span>
                  <CngIconButton
                    icon={['fal', 'plus']}
                    onClick={() => handleAddAffirmation(initialValues)}
                    size='small'
                    type='outlined'
                  />
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )}
    </CngSection>
  )
}

export default AffirmationSection
