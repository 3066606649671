import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, constants } from 'cng-web-lib'
import { Box, IconButton, Card, CardContent, Grid, Divider, Typography, InputAdornment, Tooltip } from '@material-ui/core'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngField from 'src/components/cngcomponents/CngField'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext'
import PNDetailsFormProperties from './PNDetailsFormProperties'
import moment from 'moment'
import PartyDetailsTable from './PartyDetailsTable'

const {
  button: { CngButton, CngPrimaryButton },
  form: { CngForm },
  CngDialog,
  CngGridItem
} = components

const { FormState } = constants

function PNDetailsDialog(props) {
  const {
    onAddPNDetailDTO,
    onEditPNDetailDTO,
    onAddPartyDetail,
    onDeletePartyDetail,
    onEditPartyDetail,
    isView,
    onClose,
    open,
    showNotification,
    pgaDTO,
    getLookupValue
  } = props

  const { initialValues, makeValidationSchema } = PNDetailsFormProperties.formikProps
  const { getTranslatedText } = useContext(FdaPNTranslationContext)

  return (
    <CngDialog
      dialogContent={
        isView && pgaDTO ? (
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'pgaProductNumber')}>
                    {pgaDTO.productNumber}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'anticipatedArrivalDate')}>
                    {pgaDTO.anticipatedArrivalDate &&
                      moment(pgaDTO.anticipatedArrivalDate).format('D MMM YYYY')}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'anticipatedArrivalTime')}>
                    {pgaDTO.anticipatedArrivalTime}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'pgaGovAgencyProcessingCode')}>
                    {pgaDTO.govAgencyProcessingCode && getLookupValue('govAgencyProcessing', pgaDTO.govAgencyProcessingCode)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'pgaCommercialDesc')}>
                    {pgaDTO.commercialDesc}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'pgaCommodityCharacteristicDesc')}>
                    {pgaDTO.commodityCharacteristicDesc}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'pgaCommodityCharacteristicDesc')}>
                    {pgaDTO.commodityCharacteristicDesc}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'arrivalLocation')}>
                    {pgaDTO.arrivalLocation}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'pgaPackageTrackingCode')}>
                    {pgaDTO.packageTrackingCode}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'pgaPackageTrackingNumber')}>
                    {pgaDTO.packageTrackingNumber}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'pgaCountryOfProduction')}>
                    {pgaDTO.countryOfProduction}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'pgaCountryOfShipment')}>
                    {pgaDTO.countryOfShipment}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'pgaCountryOfEntryRefusal')}>
                    {pgaDTO.countryOfEntryRefusal}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={getTranslatedText('FDAPN', 'pnConfirmationNumber')}>
                    {pgaDTO.pnConfirmationNumber}
                  </CngField>
                </Grid>

                <CngGridItem xs={12} lg={12}>
                  <CngSection title={getTranslatedText('FDAPN', 'partyTitle')}>
                    <PartyDetailsTable data={pgaDTO.party} isView={isView} onAddPartyDetail={onAddPartyDetail}
                      onDeletePartyDetail={onDeletePartyDetail}
                      onEditPartyDetail={onEditPartyDetail} />
                  </CngSection>
                </CngGridItem>

                <Grid item xs={12} sm={6}>
                  <CngSection title={getTranslatedText('FDAPN', 'packagingTitle')}>
                    {pgaDTO.packaging.length > 0 ? (
                      <Grid container spacing={2}>
                        {pgaDTO.packaging.map((packagingDTO, index) => (
                          <React.Fragment key={packagingDTO.id}>
                            <Grid item xs={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4}>
                                  <CngField label={getTranslatedText('FDAPN', 'packagingQualifier')}>
                                    {packagingDTO.packagingQualifier}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <CngField label={getTranslatedText('FDAPN', 'packagingQuantity')}>
                                    {packagingDTO.quantity}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <CngField label={getTranslatedText('FDAPN', 'packagingUOM')}>
                                    {packagingDTO.uom}
                                  </CngField>
                                </Grid>
                              </Grid>
                            </Grid>
                            {pgaDTO.packaging.length !== index + 1 && (
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                            )}
                          </React.Fragment>
                        ))}
                      </Grid>
                    ) : (
                      <Typography variant='body2' color='textSecondary'>
                        No records to display
                      </Typography>
                    )}
                  </CngSection>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CngSection title={getTranslatedText('FDAPN', 'containerInfoTitle')}>
                    {pgaDTO.containerInfo.length > 0 ? (
                      <Grid container spacing={2}>
                        {pgaDTO.containerInfo.map((containerDTO, index) => (
                          <React.Fragment key={containerDTO.id}>
                            <Grid item xs={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12}>
                                  <CngField label={getTranslatedText('FDAPN', 'containerInfoContainerNumber')}>
                                    {containerDTO.containerNumber}
                                  </CngField>
                                </Grid>
                              </Grid>
                            </Grid>
                            {pgaDTO.containerInfo.length !== index + 1 && (
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                            )}
                          </React.Fragment>
                        ))}
                      </Grid>
                    ) : (
                      <Typography variant='body2' color='textSecondary'>
                        No records to display
                      </Typography>
                    )}
                  </CngSection>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <CngSection title={getTranslatedText('FDAPN', 'pgaAdditionalPNDetails')}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={getTranslatedText('FDAPN', 'pgaIntendedUseCode')}>
                          {pgaDTO.intendedUseCode}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={getTranslatedText('FDAPN', 'pgaIntendedUseDesc')}>
                          {pgaDTO.intendedUseDesc}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={getTranslatedText('FDAPN', 'pgaRemarksCode')}>
                          {pgaDTO.remarksCode}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={getTranslatedText('FDAPN', 'pgaRemarksText')}>
                          {pgaDTO.remarksText}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={1}>
                        <CngField label={getTranslatedText('FDAPN', 'pgaLpcoIssuer')}>
                          {pgaDTO.lpcoIssuer}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <CngField label={getTranslatedText('FDAPN', 'pgaLpcoIssuerLocation')}>
                          {pgaDTO.lpcoIssuerLocation}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={getTranslatedText('FDAPN', 'pgaLpcoGovGeographicQualifier')}>
                          {pgaDTO.lpcoGovGeographicQualifier}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={getTranslatedText('FDAPN', 'pgaLpcoIssuerRegionalDesc')}>
                          {pgaDTO.lpcoIssuerRegionalDesc}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={getTranslatedText('FDAPN', 'pgaLpcoNumber')}>
                          {pgaDTO.lpcoNumber}
                        </CngField>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <CngSection title={getTranslatedText('FDAPN', 'affirmationTitle')}>
                          {pgaDTO.affirmation.length > 0 ? (
                            <Grid container spacing={2}>
                              {pgaDTO.affirmation.map((affirmationDTO, index) => (
                                <React.Fragment key={affirmationDTO.id}>
                                  <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={6} md={6}>
                                        <CngField label={getTranslatedText('FDAPN', 'affirmationCode')}>
                                          {affirmationDTO.complianceAffirmationCode}
                                        </CngField>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={6}>
                                        <CngField label={getTranslatedText('FDAPN', 'affirmationDescription')}>
                                          {affirmationDTO.complianceAffirmationDesc}
                                        </CngField>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {pgaDTO.affirmation.length !== index + 1 && (
                                    <Grid item xs={12}>
                                      <Divider />
                                    </Grid>
                                  )}
                                </React.Fragment>
                              ))}
                            </Grid>
                          ) : (
                            <Typography variant='body2' color='textSecondary'>
                              No records to display
                            </Typography>
                          )}
                        </CngSection>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <CngSection title={getTranslatedText('FDAPN', 'productCondTitle')}>
                          {pgaDTO.productCond.length > 0 ? (
                            <Grid container spacing={2}>
                              {pgaDTO.productCond.map((productCondDTO, index) => (
                                <React.Fragment key={productCondDTO.id}>
                                  <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={12} md={12}>
                                        <CngField label={getTranslatedText('FDAPN', 'productLOTNumber')}>
                                          {productCondDTO.lotNumber}
                                        </CngField>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {pgaDTO.productCond.length !== index + 1 && (
                                    <Grid item xs={12}>
                                      <Divider />
                                    </Grid>
                                  )}
                                </React.Fragment>
                              ))}
                            </Grid>
                          ) : (
                            <Typography variant='body2' color='textSecondary'>
                              No records to display
                            </Typography>
                          )}
                        </CngSection>
                      </Grid>
                    </Grid>
                  </CngSection>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: pgaDTO || initialValues,
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (pgaDTO) {
                  onEditPNDetailDTO(data)
                } else {
                  onAddPNDetailDTO(data)
                }

                onClose()
              }
            }}
            formState={FormState.COMPLETED}
            renderBodySection={(labelMap, shouldHideMap) => (
              <Box padding={1}>
                <PNDetailsFormProperties.Fields
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                />
              </Box>
            )}
            innerForm={true}
            renderButtonSection={() => (
              <Box display='flex' justifyContent='flex-end'>
                <CngPrimaryButton type='submit'>
                  {pgaDTO
                    ? getTranslatedText('FDAPN', 'updateButton')
                    : getTranslatedText('FDAPN', 'saveButton')}
                </CngPrimaryButton>
              </Box>
            )}
          />
        )
      }
      dialogAction={
        isView ? <CngButton onClick={onClose}>OK</CngButton> : null
      }
      dialogTitle={getTranslatedText('FDAPN', 'pgaTitle')}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default PNDetailsDialog
