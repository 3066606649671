import makeValidationSchema from './AddPartyMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import FdapnKeys from 'src/constants/locale/key/Fdapn'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import { useFormContext, useWatch } from 'react-hook-form';

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngSelectField,
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id:"",
  name: "",
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  address5: "",
  cityName: "",
  contactName: "",
  email: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index
}) {
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const { getValues, setValue, reset, trigger } = useFormContext();
  const { addParty } = useWatch()

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.name}>
        <CngTextField
          name={typeof index === 'number' ? `party.${index}.addParty.name` : 'addParty.name'}
          label={getTranslatedText('FDAPN', 'partyName')}
          inputProps={{ maxLength: 72 }}
          onChange={(e) => {
            setValue('addParty.name', e.target.value.toUpperCase())
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.address1}>
        <CngTextField
          name={typeof index === 'number' ? `party.${index}.addParty.address1` : 'addParty.address1'}
          label={getTranslatedText('FDAPN', 'partyAddress1')}
          inputProps={{ maxLength: 72 }}
          onChange={(e) => {
            setValue('addParty.address1', e.target.value.toUpperCase())
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.address2}>
        <CngTextField
          name={typeof index === 'number' ? `party.${index}.addParty.address2` : 'addParty.address2'}
          label={getTranslatedText('FDAPN', 'partyAddress2')}
          inputProps={{ maxLength: 72 }}
          onChange={(e) => {
            setValue('addParty.address2', e.target.value.toUpperCase())
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.address3}>
        <CngTextField
          name={typeof index === 'number' ? `party.${index}.addParty.address3` : 'addParty.address3'}
          label={getTranslatedText('FDAPN', 'partyAddress3')}
          inputProps={{ maxLength: 72 }}
          onChange={(e) => {
            setValue('addParty.address3', e.target.value.toUpperCase())
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.address4}>
        <CngTextField
          name={typeof index === 'number' ? `party.${index}.addParty.address4` : 'addParty.address4'}
          label={getTranslatedText('FDAPN', 'partyAddress4')}
          inputProps={{ maxLength: 72 }}
          onChange={(e) => {
            setValue('addParty.address4', e.target.value.toUpperCase())
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.address5}>
        <CngTextField
          name={typeof index === 'number' ? `party.${index}.addParty.address5` : 'addParty.address5'}
          label={getTranslatedText('FDAPN', 'partyAddress5')}
          inputProps={{ maxLength: 72 }}
          onChange={(e) => {
            setValue('addParty.address5', e.target.value.toUpperCase())
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.contactName}>
        <CngTextField
          name={typeof index === 'number' ? `party.${index}.addParty.contactName` : 'addParty.contactName'}
          label={getTranslatedText('FDAPN', 'partyContactFirstName')}
          inputProps={{ maxLength: 72 }}
          onChange={(e) => {
            setValue('addParty.contactName', e.target.value.toUpperCase())
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.cityName}>
        <CngTextField
          name={typeof index === 'number' ? `party.${index}.addParty.cityName` : 'addParty.cityName'}
          label={getTranslatedText('FDAPN', 'partyCityName')}
          inputProps={{ maxLength: 72 }}
          onChange={(e) => {
            setValue('addParty.cityName', e.target.value.toUpperCase())
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.email}>
        <CngTextField
          name={typeof index === 'number' ? `party.${index}.addParty.email` : 'addParty.email'}
          label={getTranslatedText('FDAPN', 'partyEmail')}
          inputProps={{ maxLength: 72 }}
          onChange={(e) => {
            setValue('addParty.email', e.target.value.toUpperCase())
          }}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const AddPartyFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AddPartyFormProperties
