import { Box, Typography, Chip, Tooltip, useTheme, Grid, Card } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import FdapnApiUrls from 'src/apiUrls/FdapnApiUrls'
import React, { useEffect, useRef, useState, useContext } from 'react'
import FdapnKeys from 'src/constants/locale/key/Fdapn'
import Namespace from 'src/constants/locale/Namespace'

import Table from '../../../components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pathMap from 'src/paths/pathMap'
import { FileForUserGetPartyId, FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import moment from 'moment-timezone'
import { getStatusMetadata } from '../../../common/NACommon'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import CSVUploadDialog from './CSVUploadDialog'
import { format } from 'date-fns'
import ChargeTypeDialog from '../../common/ChargeTypeDialog'
import sweetalert from 'sweetalert'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  filter: { BETWEEN, EQUAL, IN, LIKE }
} = constants

function TablePage(props) {
  const {
    showNotification,
    history,
    location,
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.FDAPN
  ])
  const tableRef = useRef(null)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, fdapn: null })
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const [lookups, setLookups] = useState(null)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { fetchRecords, createRecord, deleteRecord, securedSendRequest } = useServices()
  const [fdapnTemplateDialog, setFdapnTemplateDialog] = useState(false)
  const [uploadCSVFDAPNDialog, setUploadCSVFDAPNDialog] = useState({ open: false, tableRef: null })
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })
  const [isSuperParty, setSuperParty] = useState(false);
  const [chargeTypes, setChargeTypes] = useState([])

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  useEffect(() => {
    Promise.all([
      // Party Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'FDA_PN_ENTRY_TYPE' }],
        undefined,
        'code'
      ),
      // Document Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'FDA_PN_BILL_TYPE' }],
        undefined,
        'code'
      ),
      // Arrival Location
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [
            { field: 'indicator', operator: EQUAL, value: 'ACI' }
          ],
          customData: { codeMType: 'CustomsOffice' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
    ]).then(
      ([
        entryType,
        billType,
        arrivalLocation
      ]) => {
        setLookups({
          entryType,
          billType,
          arrivalLocation
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const combineDateAndTime = (date, time) => {
    if (date && time) {
      const dateTime = moment(date).set({
        hour: parseInt(time.substring(0, 2), 10),
        minute: parseInt(time.substring(2, 4), 10),
      });
      return dateTime.format('D MMM YYYY HH:mm');
    }
    return "";
  };

  const columns = [
    {
      field: 'referenceIdNum',
      sortKey: 'referenceIdNum',
      title: getTranslatedText('FDAPN', 'referenceIdNum'),
    },
    {
      field: 'entryType',
      sortKey: 'entryType',
      title: getTranslatedText('FDAPN', 'entryType'),
      render: (data) =>
        <Typography component='div' variant='inherit'>
          {getLookupValue('entryType', data.entryType)}
          {/* <TinyChip label={data.firstExpectedPort} variant='outlined' /> */}
        </Typography>
    },
    {
      field: 'billType',
      sortKey: 'billType',
      title: getTranslatedText('FDAPN', 'billType'),
      render: (data) =>
        <Typography component='div' variant='inherit'>
          {getLookupValue('billType', data.billType)}
          {/* <TinyChip label={data.firstExpectedPort} variant='outlined' /> */}
        </Typography>
    },
    {
      field: 'arrivalLocation',
      sortKey: 'arrivalLocation',
      title: getTranslatedText('FDAPN', 'arrivalLocation'),
      render: (data) =>
        <Typography component='div' variant='inherit'>
          {getLookupValue('arrivalLocation', data.arrivalLocation)}
          <TinyChip label={data.arrivalLocation} variant='outlined' />
        </Typography>
    },
    {
      field: 'anticipatedArrivalDate',
      sortKey: 'anticipatedArrivalDate',
      title: getTranslatedText('FDAPN', 'anticipatedArrivalDate'),
      render: (data) => combineDateAndTime(data.anticipatedArrivalDate, data.anticipatedArrivalTime)
      // data.anticipatedArrivalDate && moment(data.anticipatedArrivalDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'status',
      sortKey: 'status',
      title: getTranslatedText('FDAPN', 'status'),
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <>
            <Tooltip key='more' title='View response(s)' placement='top'>
              <Chip
                label={status.label}
                size='small'
                style={{
                  backgroundColor: status.color,
                  color: status.contrastColor
                }}
                clickable
              // onClick={(event) => {
              //   event.stopPropagation()
              //   setQuickGlanceResponseLogDialog({ open: true, manifest: data })                
              // }}
              />
            </Tooltip>
            <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
              {data.responseDateTime && moment(data.responseDateTime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')}
            </Typography>
          </>
        )
      }
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: getTranslatedText('FDAPN', 'createdDate'),
      render: (data) =>
        data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: getTranslatedText('FDAPN', 'submittedDate'),
      render: (data) =>
        data.submittedDate && moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'envelopeNumber',
      sortKey: 'envelopeNumber',
      title: getTranslatedText('FDAPN', 'envelopeNumber'),
    },
    {
      field: 'pnConfirmationNumber',
      sortKey: 'pnConfirmationNumber',
      title: getTranslatedText('FDAPN', 'productNumberAndPNC'),
      render: (data) => {
        let content = []
        data.pga.map(function (object, i) {
          content.push(
            <Chip key='first' label={object.productNumber + " | " + (object.pnConfirmationNumber ? object.pnConfirmationNumber : "Not Available")} />
          )
        })

        return (
          <>
            <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
              {content}
            </Box>
          </>
        )
      }
    }
  ]

  const filters = [
    {
      label: getTranslatedText('FDAPN', 'referenceIdNum'),
      type: 'textfield',
      name: 'referenceIdNum',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('FDAPN', 'entryType'),
      type: 'checkbox',
      name: 'entryType',
      operatorType: IN,
      options: [
        {
          label: 'Consumption-Free & Dutiable',
          value: '01',
          filterValue: {
            value: '01'
          }
        },
        {
          label: 'Quota/Visa',
          value: '02',
          filterValue: {
            value: '02'
          }
        },
        {
          label: 'Consumption-AD/CVD',
          value: '03',
          filterValue: {
            value: '03'
          }
        },
        {
          label: 'Consumption–Quota/Visa & AD/CVD',
          value: '07',
          filterValue: {
            value: '07'
          }
        },
        {
          label: 'Informal',
          value: '11',
          filterValue: {
            value: '11'
          }
        },
        {
          label: 'Informal–Quota/Visa',
          value: '12',
          filterValue: {
            value: '12'
          }
        },
        {
          label: 'Warehouse',
          value: '21',
          filterValue: {
            value: '21'
          }
        },
        {
          label: 'TIB',
          value: '23',
          filterValue: {
            value: '23'
          }
        },
        {
          label: 'Government',
          value: '52',
          filterValue: {
            value: '52'
          }
        },
        {
          label: 'Immediate Transportation',
          value: '61',
          filterValue: {
            value: '61'
          }
        },
        {
          label: 'Transportation and Exportation',
          value: '62',
          filterValue: {
            value: '62'
          }
        }
      ]
    },
    {
      label: getTranslatedText('FDAPN', 'billType'),
      type: 'checkbox',
      name: 'billType',
      operatorType: IN,
      options: [
        {
          label: 'Master Bill of Lading',
          value: 'M',
          filterValue: {
            value: 'M'
          }
        },
        {
          label: 'Regular/Simple Bill of Lading',
          value: 'R',
          filterValue: {
            value: 'R'
          }
        },
        {
          label: 'Express Carrier Tracking Number(Air Only)',
          value: 'T',
          filterValue: {
            value: 'T'
          }
        }
      ]
    },
    {
      label: getTranslatedText('FDAPN', 'arrivalLocation'),
      type: 'textfield',
      name: 'arrivalLocation',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('FDAPN', 'anticipatedArrivalDate'),
      type: 'daterange',
      name: 'anticipatedArrivalDate',
      operatorType: BETWEEN
    },
    {
      label: getTranslatedText('FDAPN', 'status'),
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: '1005'
          }
        },
        {
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: '1000'
          }
        },
        {
          label: 'Sent',
          value: 'sent',
          filterValue: {
            value: '1008'
          }
        },
        {
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: '1001'
          }
        },
        /*{
          label: 'Template',
          value: 'Template',
          filterValue: {
            value: '1002'
          }
        },*/
        {
          label: 'Status Notification',
          value: 'status_notification',
          filterValue: {
            value: '1006'
          }
        },
        {
          label: 'Cancellation Accepted',
          value: 'cancellation_accepted',
          filterValue: {
            value: '1003'
          }
        },
        {
          label: 'Cancellation Rejected',
          value: 'cancellation_rejected',
          filterValue: {
            value: '1004'
          }
        },
        {
          label: 'Modification Rejected',
          value: 'modification_rejected',
          filterValue: {
            value: '1007'
          }
        }
      ]
    },
    {
      label: getTranslatedText('FDAPN', 'createdDate'),
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: getTranslatedText('FDAPN', 'submittedDate'),
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: getTranslatedText('FDAPN', 'envelopeNumber'),
      type: 'textfield',
      name: 'envelopeNumber',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('FDAPN', 'productNumberAndPNC'),
      type: 'textfield',
      name: 'pnConfirmationNumber',
      operatorType: LIKE
    },
    // {
    //   label: getTranslatedText('FDAPN', 'submittedDateTime'),
    //   type: 'daterange',
    //   name: 'submittedDateTime',
    //   operatorType: BETWEEN
    // },
  ]

  function handleCloneFDAPN(data) {
    // data.cloneFrom = data.tripNum;
    createRecord.execute(
      FdapnApiUrls.CLONE,
      data,
      (data) => {
        data.anticipatedArrivalDate = moment(data.anticipatedArrivalDate).format('YYYY-MM-DD');

        if (data.bol) {
          const usedBolData = data.bol && data.bol.map(
            (bolDTO, index) => ({ ...bolDTO, _id: index + 1 }))
          data.bol = usedBolData
        }

        if (data.pga) {
          const usedPgaData = data.pga && data.pga.map(
            (pgaDTO, index) => ({ ...pgaDTO, _id: index + 1 }))
          data.pga = usedPgaData
        }

        data.pga && data.pga.forEach((datum) => {
          datum.anticipatedArrivalDate = moment(datum.anticipatedArrivalDate).format('YYYY-MM-DD');

          const usedPartyData = datum.party && datum.party.map(
            (partyDTO, index) => ({ ...partyDTO, _id: index + 1 }))
          datum.party = usedPartyData

          // datum.party && datum.party.forEach((party) => {
          //   if (party.addParty) {
          //     const usedAddPartyData = party.addParty && party.addParty.map(
          //       (addPartyDTO, index) => ({ ...addPartyDTO, _id: index + 1 }))
          //     party.addParty = usedAddPartyData
          //   }
          // })

          const usedPackagingData = datum.packaging && datum.packaging.map(
            (packagingDTO, index) => ({ ...packagingDTO, _id: index + 1 }))
          datum.packaging = usedPackagingData

          const usedContainerInfoData = datum.containerInfo && datum.containerInfo.map(
            (containerInfoDTO, index) => ({ ...containerInfoDTO, _id: index + 1 }))
          datum.containerInfo = usedContainerInfoData

          const usedAffirmationData = datum.affirmation && datum.affirmation.map(
            (affirmationDTO, index) => ({ ...affirmationDTO, _id: index + 1 }))
          datum.affirmation = usedAffirmationData

          const usedProductCondData = datum.productCond && datum.productCond.map(
            (productCondDTO, index) => ({ ...productCondDTO, _id: index + 1 }))
          datum.productCond = usedProductCondData
        });
        history.push(pathMap.FDAPN_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDeleteFDAPN() {
    if (confirmDialog.fdapn) {
      deleteRecord.execute(
        FdapnApiUrls.DELETE,
        confirmDialog.fdapn,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, fdapn: null })
          setFdapnTemplateDialog(false)
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function handlePrintFDAPN(data) {
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'GET',
      `${FdapnApiUrls.PRINT}?referenceIdNum=${data.referenceIdNum}`,
      data,
      (response) => {
        console.log("print response.data: " + JSON.stringify(response.data));
        let blob = new Blob([response.data])

        let first = 'FDAPN_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + '.pdf'
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }

  function DisableSubmitButton(rows) {
    let shouldDisable = false
    rows.forEach((rowData) => {
      if (rowData.status === '1008') {
        shouldDisable = true
      }
    })
    console.log("shouldDisable: " + shouldDisable)
    return shouldDisable
  }

  function preSubmit(rows) {
    // const values = { ...serverDataRef.current, id }

    if (isSuperParty) {
      setSubmitDialog({ open: true, items: rows })
    } else {
      // values.chargeAs = 'REGULAR'
      // serverDataRef.current = values
      const updatedRows = rows.map((item) => ({ 
        ...item, 
        chargeAs: 'REGULAR' 
      }));
      submitAll(updatedRows)
    }
  }

  async function submitAll(rows) {
    console.log("submitAll: " + JSON.stringify(rows));
    if (rows.length > 0) {

      tableRef.current.setLoading(true)
      securedSendRequest.execute('POST',
        FdapnApiUrls.SUBMIT_ALL,
        rows,
        () => {
          showNotification('success', 'Submitted successfully')
          tableRef.current.performRefresh()
        },
        (error) => {
          showNotification('error', 'Something went wrong. Submit all failed.')
          console.log(error)
        },
        () => tableRef.current.setLoading(false)
      )
    }
  }

  async function bulkPrint(rows) {
    console.log("Bulk print: " + JSON.stringify(rows));

    if (rows.length > 0) {
      const config = { responseType: 'blob' }

      let fileForUserDetails = FileForUserGetUserDetails();
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        rows.forEach(record => {
          record.fileForUserId = fileForUserDetails.fileForUserId
          record.fileForUserLoginId = fileForUserDetails.fileForUserLoginId
          record.fileForUserPartyId = fileForUserDetails.fileForUserPartyId
        })
      }

      securedSendRequest.execute('POST', FdapnApiUrls.BULK_PRINT, rows,
        (response) => {
          let blob = new Blob([response.data])

          let first = 'FDA_PN_'
          let curDate = format(new Date(), 'yyyyMMddHHmmss')
          let filename = first + curDate + '.zip'
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
        },
        (error) => console.error(error),
        undefined, config, null
      )
    }
  }

  async function downloadTransactions(data) {
    if (data.length > 0) {
      const config = { responseType: 'blob' }

      for (let i = 0; i < data.length; i++) {
        data[i].arrivalDate = null
        data[i].responseDate = null;
        data[i].submittedDate = null;
      }

      securedSendRequest.execute('POST', FdapnApiUrls.EXPORT_PN, data,
        (response) => {
          let blob = new Blob([response.data])

          let first = 'FDA_PN_TXN_EXPORT_'
          let curDate = format(new Date(), 'yyyyMMddHHmmss')
          let filename = first + curDate + '.csv'
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
        },
        (error) => console.error(error),
        undefined,
        config,
        null
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.FDAPN_ADD_VIEW)
            },
            label: 'Create FDA PN'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => setUploadCSVFDAPNDialog({ open: true, tableRef: tableRef })
            },
            label: 'FDA PN Upload'
          }
          // {
          //   buttonProps: {
          //     color: 'primary',
          //     size: 'medium',
          //     startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
          //     // onClick: () => setIsf10TemplateDialog(true)
          //   },
          //   label: 'FDA PN Template'
          // }
        ]}
        columns={columns}
        compact
        // exportData={{ url: ISF10ApiUrls.CUSTOM_EXPORT }}
        fetch={{ url: FdapnApiUrls.SEARCH_GET }}
        fetchFilters={[
          // { field: 'templateFlag', operator: EQUAL, value: '0' },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (rowData.status === '1008') {
            history.push(`${location.pathname}/view/${rowData.id}`)
          }
          else {
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }
        }
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: getTranslatedText('FDAPN', 'editButton'),
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              rowData.status === '1008'
                ? getTranslatedText('FDAPN', 'editSentRecords')
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: getTranslatedText('FDAPN', 'cloneButton'),
            onClick: handleCloneFDAPN
          },
          {
            disabled: (data) => data.status !== '1005',
            label: getTranslatedText('FDAPN', 'deleteButton'),
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (data) => setConfirmDialog({ open: true, fdapn: data }),
            tooltip: (rowData) =>
              rowData.status !== '1005'
                ? getTranslatedText('FDAPN', 'deleteRecords')
                : null
          },
          {
            label: getTranslatedText('FDAPN', 'printFDAPN'),
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            onClick: handlePrintFDAPN
          },
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
        checkboxSelection
        tablePreference={{ module: 'FDAPN', key: 'fdapn_table_visible_column' }}
        selectActions={[
          {
            // disabled: (rows) => rows.length > 500,
            icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
            label: "Submit All",
            onClick: (rows) => {
              return preSubmit(rows);
            },
            disabled: (rows) => DisableSubmitButton(rows),
            tooltip: (rows) => DisableSubmitButton(rows) ? getTranslatedText('FDAPN', 'submitMultipleRecords') : null
          },
          {
            // disabled: (rows) => rows.length > 500,
            icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
            label: "Print FDA PN",
            onClick: (rows) => {
              sweetalert("Printing FDA PN in the background. The report(s) will be downloaded automatically once ready.", "", "success");
              return bulkPrint(rows);
            }
          },
          {
            // disabled: (rows) => rows.length > 100,
            icon: <FontAwesomeIcon icon={['fal', 'link']} />,
            label: "Download",
            onClick: (rows) => {
              // if (rows.length > 100) {
              //   sweetalert("Maximum of 100 records per export.", "", "error");
              // } else {
              sweetalert("Downloading Transactions in the background. The report will be downloaded automatically once ready.", "", "success");
              return downloadTransactions(rows);
              // }
            },
            // tooltip: (rows) => rows.length > 100 ? "Maximum of 100 records per export" : null

          }          
          // {
          //   disabled: DisableDeleteButtonByStatus,
          //   icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
          //   label: translatedTextsObject.deleteButton,
          //   onClick: (rows) => setAlertDialog({ open: true, arrivalCerts: rows }),
          //   tooltip: (rows) =>
          //     DisableDeleteButtonByStatus(rows)
          //       ? translatedTextsObject.deleteMultipleRecords
          //       : null
          // }
        ]}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, fdapn: null })}
        confirmDialog={handleDeleteFDAPN}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={getTranslatedText('FDAPN', 'deleteButton')}
      />
      <CSVUploadDialog
        open={uploadCSVFDAPNDialog}
        onClose={() => setUploadCSVFDAPNDialog({ open: false, tableRef: null })}
        showNotification={showNotification}
      />
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(data) => {
          // serverDataRef.current = data
          submitAll(data)
        }}
        open={submitDialog.open}
        items={submitDialog.items}
      />
      {/*<TemplateSelectDialogWithOption
        isfTemplateDialog={isf10TemplateDialog}
        setIsfTemplateDialog={setIsf10TemplateDialog}
        setConfirmDialog={setConfirmDialog}
        showNotification={showNotification}
        isTable={true}
        location={location}
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: 'Edit',
            onClick: (rowData) =>
              history.push(pathMap.ACEISF10+`/edit/${rowData.id}`),
            tooltip: () => 'edit'
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: (rowData) =>
              setConfirmDialog({ open: true, isf10: rowData }),
            tooltip: () => 'delete'
          }
        ]}
      /> */}
    </>
  )
}

export default TablePage
