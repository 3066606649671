import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const productNumberRegex = /^\d{2}[A-Z]{1,2}-?[A-Z0-9]{1,4}$/;

  return Yup.object({
    commercialDesc: Yup.string().required(requiredMessage).nullable(),
    govAgencyProcessingCode: Yup.string().required(requiredMessage).nullable(),
    intendedUseCode: Yup.string().nullable(),
    intendedUseDesc: Yup.string().nullable(),
    productNumber: Yup.string().required(requiredMessage).matches(productNumberRegex, "Valid Format: NNA(A or -)(A or -)AN (Ex : 12DC-Q1 , 43F-U12, 37YYT01)").nullable(),
    remarksCode: Yup.string().nullable(),
    remarksText: Yup.string().nullable(),
    lpcoNumber: Yup.string().nullable(),
    lpcoIssuer: Yup.string().nullable(),
    lpcoIssuerRegionalDesc: Yup.string().nullable(),
    lpcoGovGeographicQualifier: Yup.string().nullable(),
    lpcoIssuerLocation: Yup.string().nullable(),
    anticipatedArrivalDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    anticipatedArrivalTime: Yup.string().required(requiredMessage).nullable(),
    arrivalLocation: Yup.string().required(requiredMessage).nullable(),
    totalQuantity: Yup.string().nullable(),
    // uom: Yup.string(),
    packageTrackingCode: Yup.string().matches("^[a-zA-Z0-9\-]*$", "Should be a valid 4 character/digits Tracking Code").nullable(),
    packageTrackingNumber: Yup.string().matches("^[a-zA-Z0-9\-]*$", "Should be a valid character/digits Tracking Number").nullable(),
    commodityCharacteristicDesc: Yup.string().required(requiredMessage).nullable(),
    countryOfShipment: Yup.string().nullable(),
    countryOfProduction: Yup.string().when('govAgencyProcessingCode', {
      is: (govAgencyProcessingCode) => govAgencyProcessingCode !== "NSF",
      then: Yup.string().required(requiredMessage).nullable()
    }).nullable(),
    placeOfGrowth: Yup.string().when('govAgencyProcessingCode', {
      is: (govAgencyProcessingCode) => govAgencyProcessingCode === "NSF",
      then: Yup.string().required(requiredMessage).nullable()
    }).nullable(),
    countryOfEntryRefusal: Yup.string().nullable(),
    baseUnitQuantity:Yup.string().required(requiredMessage).matches("^[0-9\-]*$", "Only digits allowed").nullable(),
    baseUnitUom: Yup.string().nullable(),
    pnConfirmationNumber: Yup.string().nullable(),
    // basketNumber: Yup.string(),
    // productId: Yup.string(),
    packaging: Yup.array().of(Yup.object({
      packagingQualifier: Yup.string().nullable(),
      quantity: Yup.string().nullable().required(requiredMessage).matches("^[0-9\-]*$", "Only digits allowed"),
      uom: Yup.string().required(requiredMessage).nullable(),
    })).nullable(),
    containerInfo: Yup.array().of(Yup.object({
      containerNumber: Yup.string().nullable()
    })).nullable(),
    affirmation: Yup.array().of(Yup.object({
      complianceAffirmationCode: Yup.string().nullable(),
      complianceAffirmationDesc: Yup.string().nullable()
    })).nullable(),
    productCond: Yup.array().of(Yup.object({
      lotNumber: Yup.string().nullable()
    })).nullable()
  })
}

export default makeValidationSchema
