import AddPage from './../productmaster/AddPage.js'
import { BasePage as CngBasePage } from 'cng-web-lib'
import EditPage from './../productmaster/EditPage.js'
import React from 'react'
import TablePage from './../productmaster/TablePage.js'
import ViewPage from './../productmaster/ViewPage.js'
import useBreadcrumbNameMap from 'src/useBreadcrumbNameMap'
import FdaPNTranslationContext from './../contexts/FDAPNTranslationContext.js'

function WrappedTablePage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <FdaPNTranslationContext>
          <TablePage showNotification={showSnackbar} {...props} />
        </FdaPNTranslationContext>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAddPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <FdaPNTranslationContext>
          <AddPage showNotification={showSnackbar} {...props} />
        </FdaPNTranslationContext>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedEditPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <FdaPNTranslationContext>
          <EditPage showNotification={showSnackbar} {...props} />
        </FdaPNTranslationContext>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <FdaPNTranslationContext>
          <ViewPage showNotification={showSnackbar} {...props} />
        </FdaPNTranslationContext>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedAddPage as AddPage,
  WrappedEditPage as EditPage,
  WrappedViewPage as ViewPage,
  WrappedTablePage as TablePage
}
