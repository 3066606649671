import React, { useEffect, useState, useContext, useCallback } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import FdapnStatusNotificationApiUrls from 'src/apiUrls/FdapnStatusNotificationApiUrls'
import CngSection from './../../../components/cngcomponents/CngSection'
import CngField from './../../../components/cngcomponents/CngField'
import IconDescButton from './../../../components/button/IconDescButton'
import StatusNotificationDialog from './StatusNotificationDialog'
import ConfirmDialog from './../../common/ConfirmDialog'
import { Box, Divider, Grid } from '@material-ui/core'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'

const {
  button: { CngButton, CngIconButton }
} = components

const {
  filter: { EQUAL }
} = constants

function StatusNotificationListPage(props) {
  const {
    history,
    pnHeaderId,
    onChangeStep,
    onSetLoading,
    onSubmitManifest,
    showNotification,
    step
  } = props

  const [data, setData] = useState([])
  const [statusNotificationDialog, setStatusNotificationDialog] = useState({
    open: false,
    statusNotification: null,
    type: 'ADD'
  })
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    statusNotification: null
  })
  const { createRecord, deleteRecord, fetchRecords, updateRecord } =
    useServices()
  const { getTranslatedText } = useContext(FdaPNTranslationContext)

  useEffect(() => {
    fetchRecords.execute(
      FdapnStatusNotificationApiUrls.GET,
      {
        filters: [{ field: 'headerId', operator: EQUAL, value: pnHeaderId }]
      },
      (res) => {
        setData(res.content)
      }
    )
  }, [])

  function handleAddStatusNotification(datum) {
    onSetLoading(true)

    createRecord.execute(
      FdapnStatusNotificationApiUrls.POST,
      datum,
      (serverData) => {
        setData((prev) => [...prev, serverData])
        showNotification('success', getTranslatedText('FDAPN', 'submitSuccessMsg'))
      },
      (error) => {
        const { errorMessages } = error.response.data

        errorMessages.forEach((message) => {
          showNotification('error', message)
        })
      },
      () => onSetLoading(false)
    )
  }

  function handleEditStatusNotification(datum) {
    onSetLoading(true)

    updateRecord.execute(
      FdapnStatusNotificationApiUrls.PUT,
      datum,
      (serverData) => {
        const clonedData = [...data]
        const index = data.findIndex((sn) => sn.id === datum.id)

        clonedData[index] = serverData

        setData(clonedData)
        showNotification('success', 'Status notification updated.')
      },
      (error) => {
        console.log(error)
      },
      () => onSetLoading(false)
    )
  }

  function handleDeleteStatusNotification() {
    const { statusNotification } = confirmDialog

    if (statusNotification) {
      onSetLoading(true)

      deleteRecord.execute(
        FdapnStatusNotificationApiUrls.DELETE,
        statusNotification,
        () => {
          setData((prev) =>
            prev.filter((sn) => sn.id !== statusNotification.id)
          )
          setConfirmDialog({ open: false, statusNotification: null })
          showNotification('success', 'Delete succeeded')
        },
        (error) => {
          console.log(error)
        },
        () => onSetLoading(false)
      )
    }
  }

  return (
    <>
      <CngSection title={getTranslatedText('FDAPN', 'statusNotification')} subheader='Optional'>
        <Grid container spacing={2}>
          {data.map((datum, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('FDAPN', 'name')}>
                          {datum.name}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('FDAPN', 'email')}>
                          {datum.email}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('FDAPN', 'phoneNumber')}>
                          {datum.phoneNumber}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('FDAPN', 'snType')}>
                          {datum.statusNotificationType}
                        </CngField>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'pen']}
                              onClick={() =>
                                setStatusNotificationDialog({
                                  open: true,
                                  statusNotification: datum,
                                  type: 'EDIT'
                                })
                              }
                              size='small'
                            />
                          </Grid>
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'copy']}
                              onClick={() =>
                                setStatusNotificationDialog({
                                  open: true,
                                  statusNotification: datum,
                                  type: 'ADD'
                                })
                              }
                              size='small'
                            />
                          </Grid>
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={() =>
                                setConfirmDialog({
                                  open: true,
                                  statusNotification: datum
                                })
                              }
                              size='small'
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {data.length !== index + 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
        <Box marginTop={2}>
          <IconDescButton
            description='Or clone the previous one'
            label='Add Status Notification'
            iconButtonProps={{
              icon: ['fal', 'plus'],
              onClick: () =>
                setStatusNotificationDialog({
                  open: true,
                  statusNotification: null,
                  type: 'ADD'
                })
            }}
          />
        </Box>
      </CngSection>
      {/* <Box marginTop={2}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs={12} md='auto'>
            <CngButton
              color='secondary'
              onClick={() =>
                history.push(pathMap.FDAPN_LIST_VIEW)
              }
              size='medium'
            >
              Discard
            </CngButton>
          </Grid>
          <Grid item xs={12} md='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  onClick={() => onChangeStep(step - 1)}
                  size='medium'
                >
                  Back
                </CngButton>
              </Grid>
              {<Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={onSubmitManifest}
                  size='medium'
                >
                  Submit manifest
                </CngButton>
              </Grid>}
            </Grid>
          </Grid>
        </Grid>
      </Box> */}
      <StatusNotificationDialog
        open={statusNotificationDialog.open}
        onAddStatusNotification={handleAddStatusNotification}
        onClose={() =>
          setStatusNotificationDialog({
            open: false,
            statusNotification: null,
            type: 'ADD'
          })
        }
        onEditStatusNotification={handleEditStatusNotification}
        pnHeaderId={pnHeaderId}
        showNotification={showNotification}
        statusNotification={statusNotificationDialog.statusNotification}
        type={statusNotificationDialog.type}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() =>
          setConfirmDialog({ open: false, statusNotification: null })
        }
        confirmDialog={handleDeleteStatusNotification}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={getTranslatedText('FDAPN', 'deleteButton')}
      />
    </>
  )
}

export default StatusNotificationListPage
