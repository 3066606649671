import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import ResponseLog from './ResponseLog'

const { button: { CngButton } } = components
const { filter: { EQUAL } } = constants

function ViewLogsHistory(props) {
  const { history, pnHeaderId, onChangeStep, onSubmitManifest, step, tripNo } = props
  const { fetchRecords } = useServices()

  const [cargoEmpty, setCargoEmpty] = useState(true);

  return (
    <>
      <ResponseLog pnHeaderId={pnHeaderId}/>
      {/* <Box marginTop={2}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs={12} md='auto'>
            <CngButton
              color='secondary'
              onClick={() =>
                history.push(pathMap.FDAPN_LIST_VIEW)
              }
              size='medium'
            >
              Discard
            </CngButton>
          </Grid>
          <Grid item xs={12} md='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton color='secondary' onClick={() => onChangeStep(step - 1)} size='medium'>
                  Back
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => cargoEmpty ? onChangeStep(step - 3) : onChangeStep(step - 2)}
                  size='medium'>
                  Go to Submit
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box> */}
    </>
  )
}

export default ViewLogsHistory
