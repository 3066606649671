import FdapnProductMasterApiUrls from 'src/apiUrls/FdapnProductMasterApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { components, constants, DataFlattener, useTranslation } from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import pathMap from 'src/paths/pathMap'
import axios from 'axios'
import ChargeTypeDialog from '../../common/ChargeTypeDialog'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import ConfirmDialog from '../../common/ConfirmDialog'
import { useFormContext, useWatch } from 'react-hook-form';
import withFormState from './../../../components/aciacehighway/hocs/withFormState'

const {
  button: { CngButton },
  form: { CngEditForm },
  CngTabs,
  table: { useFetchCodeMaintenanceLookup },
} = components

const { filter: { EQUAL } } = constants

function EditPage(props) {
  const { history, loading, location, onSetLoading, showNotification } = props
  const { id } = useParams()
  const { state } = location
  const serverDataRef = useRef()
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })
  const [isSuperParty, setSuperParty] = useState(false);
  const [chargeTypes, setChargeTypes] = useState([])
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const onSuccessCallback = useRef(() => { })

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: FdapnProductMasterApiUrls.GET
          }}
          update={{
            url: FdapnProductMasterApiUrls.PUT
          }}
          id={id}
          renderButtonSection={(_, loading) => (
            <ButtonSection
              history={history}
              onSave={() => {
                onSuccessCallback.current = (e) => {
                  onSetLoading(false)
                }
              }}
              loading={loading}
              onSetLoading={onSetLoading}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default withFormState(EditPage)

function ButtonSection(props) {
  const { history, loading, onSave, onSetLoading } = props
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { formState: { isSubmitting }, getValues, trigger } = useFormContext()
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const editTemplate = getValues() ? getValues().isEditTemplate : false;
  const serverDataRef = useRef()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])
  
  return (
    <>
      <Grid container justify='space-between'>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting || loading}
            onClick={() => setConfirmDialog(true)}
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                type='submit'
                color='primary'
                disabled={isSubmitting || loading}
                shouldShowProgress={loading}
                onClick={onSave}
              >
                {getTranslatedText('FDAPN', 'updateButton')}
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(pathMap.FDAPN_PRODUCTMASTER_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
