import React, { useEffect, useState, useContext } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext'
import PartyDetailsDialog from './PartyDetailsDialog'

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateField,
      CngTimeField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

function PartyDetailsTable(props) {
  const {
    data,
    showNotification,
    shouldHideMap,
    disabled,
    isView,
    onAddPartyDetail,
    onDeletePartyDetail,
    onEditPartyDetail,
  } = props

  const [lookups, setLookups] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [partyDTODialog, setPartyDTODialog] = useState({
    isView: false,
    open: false,
    partyDTO: null
  })
  const { fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const classes = useStyles()
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  // const { getValues, setValue, reset, trigger } = useFormContext();

  // function onAddPartyDetail(data) {
  //   const partyDTO = [...getValues('party'), data].map(
  //     (partyDTO, index) => ({ ...partyDTO, _id: index + 1 }))

  //   setValue('party', partyDTO, { shouldValidate: true })
  // }

  // function onDeletePartyDetail(data) {
  //   const partyDTO = [...getValues('party')].filter(
  //     (partyDTO) => partyDTO.id ? partyDTO.id !== data.id : partyDTO._id !== data._id
  //   )

  //   setValue('party', partyDTO, { shouldValidate: true })
  // }

  // function onEditPartyDetail(data) {
  //   const partyDTO = [...getValues('party')]
  //   const index = partyDTO.findIndex(
  //     (partyDTO) => partyDTO.id ? partyDTO.id === data.id : partyDTO._id === data._id
  //   )

  //   partyDTO[index] = data

  //   setValue('party', partyDTO, { shouldValidate: true })
  // }

  const filtered =
    searchTerm !== ''
      ? data.filter((partyDTO) =>
        partyDTO.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
      : data

  useEffect(() => {
    Promise.all([
      // Party Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'FDA_PN_PARTY_TYPE' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.COUNTRY)
    ]).then(
      ([
        partyType, country
      ]) => {
        setLookups({
          partyType, country
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  return (
    <>
      <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {data && data.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {!isView && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={() =>
                  setPartyDTODialog({
                    open: true,
                    isView: false,
                    partyDTO: null
                  })
                }
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                Add Party
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              <TableCell>{getTranslatedText('FDAPN', 'partyType')}</TableCell>
              <TableCell>{getTranslatedText('FDAPN', 'partyName')}</TableCell>
              <TableCell>{getTranslatedText('FDAPN', 'partyCityName')}</TableCell>
              <TableCell>{getTranslatedText('FDAPN', 'partyCountryCode')}</TableCell>
              {!isView && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered && filtered.length > 0 ? (
              filtered.map((partyDTO) => (
                <TableRow
                  key={Math.random()}
                  onClick={() =>
                    setPartyDTODialog({ open: true, isView: true, partyDTO })
                  }
                >
                  <TableCell>{getLookupValue('partyType', partyDTO.partyType)}</TableCell>
                  <TableCell>{partyDTO.name}</TableCell>
                  <TableCell>{partyDTO.cityName}</TableCell>
                  <TableCell>{getLookupValue('country', partyDTO.countryCode)}</TableCell>
                  {!isView && (
                    <TableCell align='right'>
                      <Grid container justify='flex-end' spacing={1}>
                        {onEditPartyDetail && (
                          <Grid item xs='auto'>
                            <Tooltip placement='bottom' title='Edit'>
                              <span>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'pen']}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    setPartyDTODialog({ open: true, isView: false, partyDTO })
                                  }}
                                  size='small'
                                />
                              </span>
                            </Tooltip>
                          </Grid>
                        )}
                        <Grid item xs='auto'>
                          <Tooltip placement='bottom' title='Clone'>
                            <span>
                              <CngIconButton
                                icon={['fal', 'copy']}
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onAddPartyDetail({ ...partyDTO, id: undefined })
                                }}
                                size='small'
                                type='outlined'
                              />
                            </span>
                          </Tooltip>
                        </Grid>
                        {onDeletePartyDetail && (
                          <Grid item xs='auto'>
                            <Tooltip placement='bottom' title='Delete'>
                              <span>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'trash']}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    onDeletePartyDetail(partyDTO)
                                  }}
                                  size='small'
                                />
                              </span>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={3 + (onEditPartyDetail || onDeletePartyDetail ? 1 : 0)}
                >
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PartyDetailsDialog
        isView={partyDTODialog.isView}
        open={partyDTODialog.open}
        onClose={() =>
          setPartyDTODialog({ open: false, isView: false, partyDTO: null })
        }
        onAddPartyDetailDTO={onAddPartyDetail}
        onEditPartyDetailDTO={onEditPartyDetail}
        showNotification={showNotification}
        partyDTO={partyDTODialog.partyDTO}
      />
    </>
  )
}

export default PartyDetailsTable
