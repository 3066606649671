import React, { useRef, useState, useEffect, useContext } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import CngSection from './../../../components/cngcomponents/CngSection'
import ResponseFdapn from './ResponseFdapn'
import ResponseSn from './ResponseSn'
import ResponseSnRefInfo from './ResponseSnRefInfo'
import FdapnResponseApiUrls from 'src/apiUrls/FdapnResponseApiUrls'

import Grid from '@material-ui/core/Grid';
import Namespace from 'src/constants/locale/Namespace'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'

const { CngTabs } = components
const {
  filter: { EQUAL }
} = constants

function ResponseLog(props) {
  const { pnHeaderId } = props
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const [fdapnData, setFdapnData] = useState([])
  const [snData, setSnData] = useState([])
  const [refInfosData, setRefInfosData] = useState([])
  // const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { fetchRecord, fetchRecords } = useServices()

  function fetchManifestResponse() {
    return fetchRecords.execute(FdapnResponseApiUrls.GET,
      {
        filters: [
          {
            field: 'headerId',
            operator: 'equal',
            value: pnHeaderId
          }
        ]
      }, (res) => res.content)
  }

  function messageResponses(respData) {
    // Get FDA PN Response Details
    const updatedResult = respData.map(item => {
      const updatedResponses = item.responses.map(response => ({
        ...response,
        batchId: item.batchId
      }));
      return {
        ...item,
        responses: updatedResponses
      };
    });

    setFdapnData(updatedResult);

    // Get Status Notification Response Details
    setSnData(respData.map(item => item.processDispositions).flat());

    // Get Status Notification Ref Info Response Details
    setRefInfosData(respData.map(item => item.refInfos).flat());
  }

  function showManifestResponse() {
    Promise.all([fetchManifestResponse()]).then(([respData]) => messageResponses(respData))
  }

  useEffect(() => {
    showManifestResponse();
    // Promise.all([
    //   fetchCodeMaintenanceLookup(
    //     constants.CodeMaintenanceType.CODE_MASTER,
    //     undefined,
    //     [
    //       {
    //         field: 'codeType',
    //         operator: EQUAL,
    //         value: 'ACI_HW_SUBMISSION_TYPE'
    //       }
    //     ],
    //     undefined,
    //     'code'
    //   ),
    //   fetchCodeMaintenanceLookup(
    //     constants.CodeMaintenanceType.CODE_MASTER,
    //     undefined,
    //     [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_STATUS' }],
    //     undefined,
    //     'code'
    //   )
    // ]).then(([submissionType, status]) => {
    //   setLookups({ submissionType, status })
    // })
  }, [])

  console.log("snData: " + JSON.stringify(snData));
  console.log("refInfosData: " + JSON.stringify(refInfosData));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection title={getTranslatedText('FDAPN', 'responsesFdapn')}>
          <ResponseFdapn
            id={pnHeaderId}
            isViewOnly={true}
            fdapnData={fdapnData}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={getTranslatedText('FDAPN', 'responsesSn')}>
          <ResponseSn
            id={pnHeaderId}
            isViewOnly={true}
            snData={snData}
          />
          &nbsp;
          <ResponseSnRefInfo
            id={pnHeaderId}
            isViewOnly={true}
            refInfosData={refInfosData}
          />
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default ResponseLog
