import React from 'react'
import StatusBar from './../../../components/aciacehighway/StatusBar'
import CngSection from './../../../components/cngcomponents/CngSection'
import CngSubSection from './../../../components/cngcomponents/CngSubSection'
import CngField from './../../../components/cngcomponents/CngField'
import TinyChip from './../../../components/aciacehighway/TinyChip'
import ResponseLog from './ResponseLog'
import History from './History'
import moment from 'moment'
import { Box, Card, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BillOfLadingTable from './BillOfLadingTable'
import PNDetailsTable from './PNDetailsTable'

function ViewContent(props) {
  const {
    data,
    getCountryStateLabel,
    getLookupValue,
    id,
    isReviewPage,
    getTranslatedText
  } = props

  const theme = useTheme()

  function getPersonDocumentDTOValues() {
    return data.personDTO.map((personDTO) => personDTO.personDocumentDTO).flat()
  }

  function getSealNos() {
    const result = []

    // Loop 20 times because of API response's structure
    for (let i = 1; i <= 20; i++) {
      if (data[`sealNo${i}`]) {
        result.push(data[`sealNo${i}`])
      }
    }

    return result
  }

  const combineDateAndTime = (date, time) => {
    if (date && time) {
      const dateTime = moment(date).set({
        hour: parseInt(time.substring(0, 2), 10),
        minute: parseInt(time.substring(2, 4), 10),
      });
      return dateTime.format('D MMM YYYY HH:mm');
    }
    return "";
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StatusBar status={data.status} />
      </Grid>
      <Grid item xs={12}>
        <CngSection title={getTranslatedText('FDAPN', 'pnHeader')}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={getTranslatedText('FDAPN', 'mot')}>
                {getLookupValue('mot', data.mot)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={getTranslatedText('FDAPN', 'referenceQualifier')}>
                {getLookupValue('referenceQualifier', data.referenceQualifier)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={getTranslatedText('FDAPN', 'referenceIdNum')}>
                {data.referenceIdNum}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={getTranslatedText('FDAPN', 'anticipatedArrivalDate')}>
                {combineDateAndTime(data.anticipatedArrivalDate, data.anticipatedArrivalTime)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={getTranslatedText('FDAPN', 'arrivalLocation')}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('arrivalLocationList', data.arrivalLocation)}
                  <TinyChip label={data.arrivalLocation} />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={getTranslatedText('FDAPN', 'submitterOfEntry')}>
                {getLookupValue('yesOrNo', data.submitterOfEntry)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={getTranslatedText('FDAPN', 'entryType')}>
                {getLookupValue('entryType', data.entryType)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={getTranslatedText('FDAPN', 'billType')}>
                {getLookupValue('billType', data.billType)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={getTranslatedText('FDAPN', 'carrierCode')}>
                {data.carrierCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={getTranslatedText('FDAPN', 'filerCode')}>
                {data.filerCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={getTranslatedText('FDAPN', 'envelopeNumber')}>
                {data.envelopeNumber}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={getTranslatedText('FDAPN', 'basketNumber')}>
                {data.basketNumber}
              </CngField>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          title={
            <Box display='flex' alignItems='baseline' style={{ gap: 4 }}>
              <Typography style={{ fontWeight: 600 }}>
                {getTranslatedText('FDAPN', 'bolTitle')}
              </Typography>
              <Typography color='textSecondary' variant='caption'>
                {`/ ${data.bol.length} Entries`}
              </Typography>
            </Box>
          }
        >
          <BillOfLadingTable data={data.bol} />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          title={
            <Box display='flex' alignItems='baseline' style={{ gap: 4 }}>
              <Typography style={{ fontWeight: 600 }}>
                {getTranslatedText('FDAPN', 'pgaTitle')}
              </Typography>
              <Typography color='textSecondary' variant='caption'>
                {`/ ${data.pga.length} Entries`}
              </Typography>
            </Box>
          }
        >
          <PNDetailsTable data={data.pga} />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={getTranslatedText('FDAPN', 'statusNotification')}>
          {data.statusNotificationInfo.length > 0 ? (
            <Grid container spacing={3}>
              {data.statusNotificationInfo.map((notification, index) => (
                <React.Fragment key={notification.id}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('FDAPN', 'name')}>
                          {notification.name}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('FDAPN', 'email')}>
                          {notification.email}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('FDAPN', 'snType')}>
                          {notification.statusNotificationType}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={getTranslatedText('FDAPN', 'phoneNumber')}>
                          {notification.phoneNumber}
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {data.statusNotificationInfo.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
      {!isReviewPage && (
        <>
          <Grid item xs={12}>
            <ResponseLog pnHeaderId={id} />
          </Grid>
          <Grid item xs={12}>
            <History pnHeaderId={id} />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ViewContent
