import makeValidationSchema from './PackagingMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import FdapnKeys from 'src/constants/locale/key/Fdapn'
import { Card, CardContent, Grid } from '@material-ui/core'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import { useFormContext, useWatch } from 'react-hook-form';
import {
    components,
} from 'cng-web-lib'

const {
    card: {
        CngSimpleCardHeader,
    },
    form: {
        field: {
            CngSelectField,
            CngTextField,
            CngUomAutocompleteField,
            CngCodeMasterAutocompleteField
        },
    },
    CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
    packagingQualifier: "",
    quantity: "",
    uom: "",
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

function Fields({
    disabled,
    showNotification,
    parentIdDropdownItems,
    shouldHideMap,
    packagingDTO,
    index
}) {
    const { getTranslatedText } = useContext(FdaPNTranslationContext)
    const { getValues, setValue, reset, trigger } = useFormContext();
    const { _id: _packagingId, uom } = useWatch()

    return (
        <CngGridItem xs={12} md>
            <Grid container spacing={1}>
                <CngGridItem xs={12} md={2} lg={2}>
                    <CngTextField
                        disabled={true}
                        name={typeof index === 'number' ? `packaging.${index}.packagingQualifier` : 'packagingQualifier'}
                        label={getTranslatedText('FDAPN', 'packagingQualifier')}
                        onChange={(e) => {
                            setValue('packagingQualifier', e.target.value.toUpperCase())
                        }}
                        size='small'
                    />
                </CngGridItem>
                <CngGridItem xs={12} md={5} lg={5}>
                    <CngTextField
                        required
                        name={typeof index === 'number' ? `packaging.${index}.quantity` : 'quantity'}
                        label={getTranslatedText('FDAPN', 'packagingQuantity')}
                        onChange={(e) => {
                            setValue('quantity', e.target.value.toUpperCase())
                        }}
                        inputProps={{ maxLength: 12 }}
                        size='small'
                    />
                </CngGridItem>
                <CngGridItem xs={12} md={5} lg={5}>
                    <CngCodeMasterAutocompleteField
                        required
                        name={typeof index === 'number' ? `packaging.${index}.uom` : 'uom'}
                        label={getTranslatedText('FDAPN', 'packagingUOM')}
                        codeType='FDA_PN_UOM'
                        size='small'
                        key={uom}
                    />
                </CngGridItem>
            </Grid>
        </CngGridItem>
    )
}

const PackagingFormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields
})

export default PackagingFormProperties
