import makeValidationSchema from './PNDetailsMakeValidationSchema'
import { useTranslation, constants } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import FdapnKeys from 'src/constants/locale/key/Fdapn'
import { Box, IconButton, Card, CardContent, Grid, Divider, Typography, InputAdornment, Tooltip } from '@material-ui/core'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NaFDAPNProductMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { NaCustomsOfficeAutocompleteField, NaSublocationWithoutCodeAutocompleteField, FDAPNArrivalPortAutocompleteField } from 'src/components/na'
import {
  components,
} from 'cng-web-lib'
import CngSection from 'src/components/cngcomponents/CngSection'
import PartyDetailsTable from './PartyDetailsTable'
import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import { NaStateCodeAutoCompleteField } from 'src/components/na'
import { FileForUserGetPartyId, FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import PackagingSection from './sections/PackagingSection'
import ContainerSection from './sections/ContainerSection'
import AffirmationSection from './sections/AffirmationSection'
import ProductCondSection from './sections/ProductCondSection'
import moment from 'moment-timezone'
import TemplateSelectDialog from './../../../components/aciacehighway/TemplateSelectDialog'
import FdapnProductMasterApiUrls from './../../../apiUrls/FdapnProductMasterApiUrls'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCheckboxField,
      CngDateField,
      CngTimeField,
      CngCountryAutocompleteField,
    },
  },
  button: { CngIconButton },
  CngGridItem,
  table: {
    useFetchCodeMaintenanceLookup
  },
} = components

const {
  CodeMaintenanceType,
  filter: { LIKE, EQUAL, IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  commercialDesc: "",
  govAgencyProcessingCode: "",
  intendedUseCode: "",
  intendedUseDesc: "",
  productNumber: "",
  remarksCode: "",
  remarksText: "",
  lpcoNumber: "",
  lpcoIssuer: "",
  lpcoIssuerRegionalDesc: "",
  lpcoGovGeographicQualifier: "",
  lpcoIssuerLocation: "",
  anticipatedArrivalDate: moment().tz("Canada/Eastern").format('YYYY-MM-DD'),
  anticipatedArrivalTime: "23:59",
  arrivalLocation: "",
  totalQuantity: 0,
  uom: "",
  packageTrackingCode: "",
  packageTrackingNumber: "",
  commodityCharacteristicDesc: "",
  countryOfShipment: "",
  countryOfProduction: "",
  placeOfGrowth: "",
  countryOfEntryRefusal: "",
  baseUnitQuantity: 0,
  baseUnitUom: "",
  pnConfirmationNumber: "",
  basketNumber: "",
  saveProductFlag: "",
  productIdentification: "",
  productId: "",
  version: "",
  party: [],
  addParty: [],
  packaging: [],
  containerInfo: [],
  affirmation: [],
  productCond: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  shouldHideMap,
  index
}) {
  const { translate } = useTranslation(Namespace.FDAPN)
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const { getValues, setValue, reset, trigger, watch } = useFormContext();
  const saveProductFlag = useWatch({ name: 'saveProductFlag' });
  const [templateDialog, setTemplateDialog] = useState(false)
  const [pnDetailsExplanded, setpnDetailsExplanded] = useState(false)
  const [insertFromTemplate, setInsertFromTemplate] = useState(false)
  const { govAgencyProcessingCode, arrivalLocation, countryOfShipment, countryOfProduction, placeOfGrowth, countryOfEntryRefusal, party, containerInfo, intendedUseCode, intendedUseDesc, remarksCode, remarksText, lpcoIssuerLocation, lpcoGovGeographicQualifier, affirmation, productCond, lpcoIssuer, lpcoIssuerRegionalDesc, lpcoNumber } = useWatch()
  const [isSuperParty, setIsSuperParty] = useState(false)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const packaging = watch('packaging')

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `pga.${index}.${field}` : field
  }, [])

  const columns = [
    {
      field: 'productId',
      title: getTranslatedText('FDAPN', 'pgaProductId')
    },
    {
      field: 'productNumber',
      title: getTranslatedText('FDAPN', 'pgaProductNumber')
    }
  ]

  function handleApplyTemplate(template) {
    if (!template) {
      reset()
      return
    }

    const {
      productId,
      productNumber,
      commercialDesc,
      govAgencyProcessingCode,
      intendedUseCode,
      intendedUseDesc,
      remarksCode,
      remarksText,
      lpcoNumber,
      lpcoIssuer,
      lpcoIssuerRegionalDesc,
      lpcoGovGeographicQualifier,
      lpcoIssuerLocation,
      anticipatedArrivalDate,
      anticipatedArrivalTime,
      arrivalLocation,
      totalQuantity,
      uom,
      packageTrackingCode,
      packageTrackingNumber,
      commodityCharacteristicDesc,
      countryOfShipment,
      countryOfProduction,
      placeOfGrowth,
      countryOfEntryRefusal,
      baseUnitQuantity,
      baseUnitUom,
      basketNumber,
      party,
      packaging,
      containerInfo,
      affirmation,
      productCond,
    } = template

    setValue('id', null)
    setValue('productId', productId || '', { shouldDirty: true })
    setValue('productNumber', productNumber || '', { shouldDirty: true })
    setValue('commercialDesc', commercialDesc || '', { shouldDirty: true })
    setValue('govAgencyProcessingCode', govAgencyProcessingCode || '', { shouldDirty: true })
    setValue('intendedUseCode', intendedUseCode || '', { shouldDirty: true })
    setValue('intendedUseDesc', intendedUseDesc || '', { shouldDirty: true })
    setValue('remarksCode', remarksCode || '', { shouldDirty: true })
    setValue('remarksText', remarksText || '', { shouldDirty: true })
    setValue('lpcoIssuer', lpcoIssuer || '', { shouldDirty: true })
    setValue('lpcoNumber', lpcoNumber || '', { shouldDirty: true })
    setValue('lpcoIssuerRegionalDesc', lpcoIssuerRegionalDesc || '', { shouldDirty: true })
    setValue('lpcoGovGeographicQualifier', lpcoGovGeographicQualifier || '', { shouldDirty: true })
    setValue('lpcoIssuerLocation', lpcoIssuerLocation || '', { shouldDirty: true })
    setValue('anticipatedArrivalDate', anticipatedArrivalDate || '', { shouldDirty: true })
    setValue('anticipatedArrivalTime', anticipatedArrivalTime || '', { shouldDirty: true })
    setValue('arrivalLocation', arrivalLocation || '', { shouldDirty: true })
    setValue('totalQuantity', totalQuantity || '', { shouldDirty: true })
    setValue('uom', uom || '', { shouldDirty: true })
    setValue('packageTrackingCode', packageTrackingCode || '', { shouldDirty: true })
    setValue('packageTrackingNumber', packageTrackingNumber || '', { shouldDirty: true })
    setValue('commodityCharacteristicDesc', commodityCharacteristicDesc || '', { shouldDirty: true })
    setValue('countryOfShipment', countryOfShipment || '', { shouldDirty: true })
    setValue('countryOfProduction', countryOfProduction || '', { shouldDirty: true })
    setValue('placeOfGrowth', placeOfGrowth || '', { shouldDirty: true })
    setValue('countryOfEntryRefusal', countryOfEntryRefusal || '', { shouldDirty: true })
    setValue('baseUnitQuantity', baseUnitQuantity || '', { shouldDirty: true })
    setValue('baseUnitUom', baseUnitUom || '', { shouldDirty: true })
    setValue('basketNumber', basketNumber || '', { shouldDirty: true })
    setValue('party', party || '', { shouldDirty: true })
    setValue('packaging', packaging || '', { shouldDirty: true })
    setValue('containerInfo', containerInfo || '', { shouldDirty: true })
    setValue('affirmation', affirmation || '', { shouldDirty: true })
    setValue('productCond', productCond || '', { shouldDirty: true })

    setInsertFromTemplate(true);

    trigger()
  }

  useEffect(() => {
    if (affirmation.length > 0 || productCond.length > 0 || intendedUseCode || intendedUseDesc || lpcoIssuer || lpcoIssuerLocation || lpcoIssuerRegionalDesc || remarksCode || remarksText || lpcoNumber || lpcoGovGeographicQualifier) {
      setpnDetailsExplanded(true);
    }
  }, [insertFromTemplate]);

  function onAddPartyDetail(data) {
    const partyDTO = [...getValues('party'), data].map(
      (partyDTO, index) => {
        const updatedParty = {
          ...partyDTO,
          _id: index + 1
        };

        if (partyDTO.addParty) {
          updatedParty.addParty = {
            ...partyDTO.addParty,
            _id: index + 1,
            id: null,
            updatedBy: null,
            updatedDate: null,
            createdBy: null,
            createdDate: null,
            oriCreatedBy: null,
            oriUpdatedBy: null,
            oriSubmittedBy: null
          };
        }

        return updatedParty;
      });
    setValue('party', partyDTO, { shouldValidate: true })
  }

  function onDeletePartyDetail(data) {
    const partyDTO = [...getValues('party')].filter(
      (partyDTO) => partyDTO.id ? partyDTO.id !== data.id : partyDTO._id !== data._id
    )

    setValue('party', partyDTO, { shouldValidate: true })
  }

  function onEditPartyDetail(data) {
    const partyDTO = [...getValues('party')]
    const index = partyDTO.findIndex(
      (partyDTO) => partyDTO.id ? partyDTO.id === data.id : partyDTO._id === data._id
    )

    partyDTO[index] = data

    setValue('party', partyDTO, { shouldValidate: true })
  }

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }
      ])
    ]).then(([billingSuperParty]) => {
      if (Object.keys(billingSuperParty).includes(GetLoggedInUserPartyName())) {

        setIsSuperParty(true)
      }
    })
  }, [])

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <CngGridItem xs={12} lg={4}>
          </CngGridItem>
          <CngGridItem xs={12} lg={8}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.saveProductFlag}>
                <CngCheckboxField
                  label={
                    <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                      {getTranslatedText('FDAPN', 'saveProductFlag')}
                    </Typography>
                  }
                  name='saveProductFlag'
                  disabled={disabled}
                  onChange={(e) => {
                    setValue('saveProductFlag', e.target.checked)

                    if (!e.target.checked) {
                      setValue('partyIdentification', "")
                    }
                  }}
                  size='small'
                  style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.productId}>
                <CngTextField
                  name={typeof index === 'number' ? `pga.${index}.productId` : 'productId'}
                  inputProps={{
                    style: { textTransform: 'uppercase' },
                    maxLength: 35
                  }}
                  label={getTranslatedText('FDAPN', 'productIdentification')}
                  disabled={disabled || !saveProductFlag}
                  onBlur={(e) => {
                    setValue('productId', e.target.value.toUpperCase())
                  }}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <NaFDAPNProductMasterAutoCompleteField
                  name='partyDropDown'
                  label={getTranslatedText('FDAPN', 'productIdLookup')}
                  disabled={disabled}
                  onChange={(_, options) => handleApplyTemplate(options.data)}
                  lookupProps={{
                    filters: [{
                      field: 'partyId',
                      operator: EQUAL,
                      value: FileForUserGetPartyId()
                    }
                    ]
                  }}
                  size='small'
                  fullWidth
                  disableClearable
                  textFieldProps={{
                    InputProps: {
                      customEndAdornment: () => (
                        <InputAdornment position='end' style={{ marginTop: -16 }}>
                          <IconButton onClick={(event) => {
                            event.stopPropagation()
                            setTemplateDialog(true)
                          }}>
                            <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                              <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                            </Box>
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }}
                  forcePopupIcon={false}
                />
              </CngGridItem>
            </Grid>
          </CngGridItem>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.productNumber}>
            <CngTextField
              required
              name={typeof index === 'number' ? `pga.${index}.productNumber` : 'productNumber'}
              label={getTranslatedText('FDAPN', 'pgaProductNumber')}
              onChange={(e) => {
                setValue('productNumber', e.target.value.toUpperCase())
              }}
              inputProps={{ maxLength: 7 }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.anticipatedArrivalDate}>
            <CngDateField
              required
              name="anticipatedArrivalDate"
              label={getTranslatedText('FDAPN', 'anticipatedArrivalDate')}
              format="YYYY-MM-DD"
              disabled={disabled || getValues('status') === '1000'}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.anticipatedArrivalTime}>
            <CngTimeField
              required
              name="anticipatedArrivalTime"
              label={getTranslatedText('FDAPN', 'anticipatedArrivalTime')}
              format="HH:mm"
              disabled={disabled}
              size='small'
              ampm={false}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.govAgencyProcessingCode}>
            <CngCodeMasterAutocompleteField
              required
              name={typeof index === 'number' ? `pga.${index}.govAgencyProcessingCode` : 'govAgencyProcessingCode'}
              label={getTranslatedText('FDAPN', 'pgaGovAgencyProcessingCode')}
              codeType='FDA_PN_GOV_AGENCY_PROCESSING'
              size='small'
              disabled={disabled}
              key={govAgencyProcessingCode}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.commercialDesc}>
            <CngTextField
              required
              name={typeof index === 'number' ? `pga.${index}.commercialDesc` : 'commercialDesc'}
              label={getTranslatedText('FDAPN', 'pgaCommercialDesc')}
              inputProps={{ maxLength: 70 }}
              onChange={(e) => {
                setValue('commercialDesc', e.target.value.toUpperCase(), { shouldValidate: true })
              }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.commodityCharacteristicDesc}>
            <CngTextField
              required
              name={typeof index === 'number' ? `pga.${index}.commodityCharacteristicDesc` : 'commodityCharacteristicDesc'}
              label={getTranslatedText('FDAPN', 'pgaCommodityCharacteristicDesc')}
              inputProps={{ maxLength: 57 }}
              onChange={(e) => {
                setValue('commodityCharacteristicDesc', e.target.value.toUpperCase(), { shouldValidate: true })
              }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.arrivalLocation}>
            <FDAPNArrivalPortAutocompleteField
              required
              name={typeof index === 'number' ? `pga.${index}.arrivalLocation` : 'arrivalLocation'}
              label={getTranslatedText('FDAPN', 'arrivalLocation')}
              disabled={disabled}
              size='small'
              key={arrivalLocation}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.packageTrackingCode}>
            <CngTextField
              name={typeof index === 'number' ? `pga.${index}.packageTrackingCode` : 'packageTrackingCode'}
              label={getTranslatedText('FDAPN', 'pgaPackageTrackingCode')}
              onChange={(e) => {
                setValue('packageTrackingCode', e.target.value.toUpperCase())
              }}
              inputProps={{ maxLength: 4 }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.packageTrackingNumber}>
            <CngTextField
              name={typeof index === 'number' ? `pga.${index}.packageTrackingNumber` : 'packageTrackingNumber'}
              label={getTranslatedText('FDAPN', 'pgaPackageTrackingNumber')}
              onChange={(e) => {
                setValue('packageTrackingNumber', e.target.value.toUpperCase())
              }}
              inputProps={{ maxLength: 50 }}
              size='small'
            />
          </CngGridItem>
          {govAgencyProcessingCode !== 'NSF' && (<CngGridItem xs={12} md={4} lg={4} shouldHide={shouldHideMap?.countryOfProduction}>
            <CngCountryAutocompleteField
              required={govAgencyProcessingCode !== 'NSF'}
              name={typeof index === 'number' ? `pga.${index}.countryOfProduction` : 'countryOfProduction'}
              label={getTranslatedText('FDAPN', 'pgaCountryOfProduction')}
              disabled={disabled}
              size='small'
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
              key={countryOfProduction}
            />
          </CngGridItem>)}
          {govAgencyProcessingCode === 'NSF' && (<CngGridItem xs={12} md={4} lg={4} shouldHide={shouldHideMap?.placeOfGrowth}>
            <CngCountryAutocompleteField
              required={govAgencyProcessingCode === 'NSF'}
              name={typeof index === 'number' ? `pga.${index}.placeOfGrowth` : 'placeOfGrowth'}
              label={getTranslatedText('FDAPN', 'pgaPlaceOfGrowth')}
              disabled={disabled}
              size='small'
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
              key={placeOfGrowth}
            />
          </CngGridItem>)}
          <CngGridItem xs={12} md={4} lg={4} shouldHide={shouldHideMap?.countryOfShipment}>
            <CngCountryAutocompleteField
              name={typeof index === 'number' ? `pga.${index}.countryOfShipment` : 'countryOfShipment'}
              label={getTranslatedText('FDAPN', 'pgaCountryOfShipment')}
              disabled={disabled}
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
              size='small'
              key={countryOfShipment}
            />
          </CngGridItem>
          <CngGridItem xs={12} md={4} lg={4} shouldHide={shouldHideMap?.countryOfEntryRefusal}>
            <CngCountryAutocompleteField
              name={typeof index === 'number' ? `pga.${index}.countryOfEntryRefusal` : 'countryOfEntryRefusal'}
              inputProps={{ maxLength: 2 }}
              label={getTranslatedText('FDAPN', 'pgaCountryOfEntryRefusal')}
              disabled={disabled}
              key={countryOfEntryRefusal}
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.pnConfirmationNumber}>
            <CngTextField
              disabled={!isSuperParty}
              name={typeof index === 'number' ? `pga.${index}.pnConfirmationNumber` : 'pnConfirmationNumber'}
              label={getTranslatedText('FDAPN', 'pnConfirmationNumber')}
              onChange={(e) => {
                setValue('pnConfirmationNumber', e.target.value.toUpperCase())
              }}
              size='small'
            />
          </CngGridItem>

          <CngGridItem xs={12} lg={12}>
            <CngSection title={getTranslatedText('FDAPN', 'partyTitle')}>
              <PartyDetailsTable
                data={party}
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
                disabled={disabled}
                onAddPartyDetail={onAddPartyDetail}
                onDeletePartyDetail={onDeletePartyDetail}
                onEditPartyDetail={onEditPartyDetail}
              />
            </CngSection>
          </CngGridItem>

          <Grid item xs={12} sm={6}>
            <PackagingSection
              // packaging={packaging}
              showNotification={showNotification}
            // title='Driver / Crew / Passenger'
            // crewSectionHelper={translatedTextsObject.crewSectionHelper}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ContainerSection
              // packaging={packaging}
              showNotification={showNotification}
            // title='Driver / Crew / Passenger'
            // crewSectionHelper={translatedTextsObject.crewSectionHelper}
            />
          </Grid>

          <Grid item xs={12}>
            <CollapsibleSection
              defaultExpanded={pnDetailsExplanded}
              title={getTranslatedText('FDAPN', 'pgaAdditionalPNDetails')}
            >
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.intendedUseCode}>
                  <CngCodeMasterAutocompleteField
                    name={typeof index === 'number' ? `pga.${index}.intendedUseCode` : 'intendedUseCode'}
                    label={getTranslatedText('FDAPN', 'pgaIntendedUseCode')}
                    codeType='FDA_PN_INTENDED_USE'
                    size='small'
                    disabled={disabled}
                    key={intendedUseCode}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.intendedUseDesc}>
                  <CngTextField
                    name={typeof index === 'number' ? `pga.${index}.intendedUseDesc` : 'intendedUseDesc'}
                    label={getTranslatedText('FDAPN', 'pgaIntendedUseDesc')}
                    onChange={(e) => {
                      setValue('intendedUseDesc', e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.remarksCode}>
                  <CngCodeMasterAutocompleteField
                    name={typeof index === 'number' ? `pga.${index}.remarksCode` : 'remarksCode'}
                    label={getTranslatedText('FDAPN', 'pgaRemarksCode')}
                    codeType='FDA_PN_REMARKS_CODE'
                    size='small'
                    disabled={disabled}
                    key={remarksCode}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.remarksText}>
                  <CngTextField
                    name={typeof index === 'number' ? `pga.${index}.remarksText` : 'remarksText'}
                    label={getTranslatedText('FDAPN', 'pgaRemarksText')}
                    inputProps={{ maxLength: 35 }}
                    onChange={(e) => {
                      setValue('remarksText', e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.lpcoIssuer}>
                  <CngTextField
                    name={typeof index === 'number' ? `pga.${index}.lpcoIssuer` : 'lpcoIssuer'}
                    label={getTranslatedText('FDAPN', 'pgaLpcoIssuer')}
                    inputProps={{ maxLength: 35 }}
                    onChange={(e) => {
                      setValue('lpcoIssuer', e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={1} shouldHide={shouldHideMap.lpcoGovGeographicQualifier}>
                  <CngCodeMasterAutocompleteField
                    name={typeof index === 'number' ? `pga.${index}.lpcoGovGeographicQualifier` : 'lpcoGovGeographicQualifier'}
                    label={getTranslatedText('FDAPN', 'pgaLpcoGovGeographicQualifier')}
                    codeType='FDA_PN_LPCO_LOCATION_ID'
                    size='small'
                    disabled={disabled}
                    key={lpcoGovGeographicQualifier}
                    onChange={(e) => {
                      setValue('lpcoIssuerLocation', '')
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={2} shouldHide={shouldHideMap.lpcoIssuerLocation}>
                  {lpcoGovGeographicQualifier !== 'ISO' && <NaStateCodeAutoCompleteField
                    name={typeof index === 'number' ? `pga.${index}.lpcoIssuerLocation` : 'lpcoIssuerLocation'}
                    label={getTranslatedText('FDAPN', 'pgaLpcoIssuerLocation')}
                    disabled={disabled}
                    countryCode={lpcoGovGeographicQualifier === 'PR' ? 'CA' : lpcoGovGeographicQualifier === 'MS' ? 'MX' : lpcoGovGeographicQualifier}
                    key={lpcoIssuerLocation + lpcoGovGeographicQualifier}
                    size='small'
                  />}
                  {lpcoGovGeographicQualifier === 'ISO' && <CngCountryAutocompleteField
                    name={typeof index === 'number' ? `pga.${index}.lpcoIssuerLocation` : 'lpcoIssuerLocation'}
                    label={getTranslatedText('FDAPN', 'pgaLpcoIssuerLocation')}
                    disabled={disabled}
                    key={lpcoIssuerLocation + lpcoGovGeographicQualifier}
                    lookupProps={{
                      label: (record) => `${record.code};${record.descriptionEn}`
                    }}
                    size='small'
                  />}
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.lpcoIssuerRegionalDesc}>
                  <CngTextField
                    name={typeof index === 'number' ? `pga.${index}.lpcoIssuerRegionalDesc` : 'lpcoIssuerRegionalDesc'}
                    label={getTranslatedText('FDAPN', 'pgaLpcoIssuerRegionalDesc')}
                    onChange={(e) => {
                      setValue('lpcoIssuerRegionalDesc', e.target.value.toUpperCase())
                    }}
                    inputProps={{ maxLength: 25 }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.lpcoNumber}>
                  <CngTextField
                    name={typeof index === 'number' ? `pga.${index}.lpcoNumber` : 'lpcoNumber'}
                    label={getTranslatedText('FDAPN', 'pgaLpcoNumber')}
                    onChange={(e) => {
                      setValue('lpcoNumber', e.target.value.toUpperCase())
                    }}
                    inputProps={{ maxLength: 33 }}
                    size='small'
                  />
                </CngGridItem>

                <Grid item xs={12} sm={6}>
                  <AffirmationSection
                    showNotification={showNotification}
                  // title='Driver / Crew / Passenger'
                  // crewSectionHelper={translatedTextsObject.crewSectionHelper}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ProductCondSection
                    showNotification={showNotification}
                  // title='Driver / Crew / Passenger'
                  // crewSectionHelper={translatedTextsObject.crewSectionHelper}
                  />
                </Grid>
              </Grid>
            </CollapsibleSection>
          </Grid>
        </Grid>
        <TemplateSelectDialog
          columns={columns}
          defaultSorts={[{ field: 'productId', direction: 'ASC' }]}
          fetch={{ url: FdapnProductMasterApiUrls.GET }}
          fetchFilters={[
            { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
          ]}
          onApplyTemplate={handleApplyTemplate}
          onClose={() => setTemplateDialog(false)}
          open={templateDialog}
          search={{ field: 'productId', operator: LIKE }}
          title='Product Template'
        />
      </CardContent>
    </Card>
  )
}

const PNDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PNDetailsFormProperties
