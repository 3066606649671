import React, { useEffect, useState, useContext, useCallback } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import StatusNotificationFormProperties from './StatusNotificationFormProperties'
import Box from '@material-ui/core/Box'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'

const {
  button: { CngButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function StatusNotificationDialog(props) {
  const {
    open,
    onAddStatusNotification,
    onClose,
    onEditStatusNotification,
    pnHeaderId,
    showNotification,
    statusNotification,
    type
  } = props

  const { initialValues, makeValidationSchema } =
    StatusNotificationFormProperties.formikProps

  const { getTranslatedText } = useContext(FdaPNTranslationContext)

  return (
    <CngDialog
      dialogContent={
        <CngForm
          fieldLevel='form'
          formikProps={{
            initialValues: {
              ...(statusNotification || initialValues),
              headerId: pnHeaderId
            },
            makeValidationSchema: makeValidationSchema,
            onSubmit: (data) => {
              if (type === 'EDIT') {
                onEditStatusNotification(data)
              } else {
                delete data.id

                onAddStatusNotification(data)
              }
              onClose()
            }
          }}
          formState={FormState.COMPLETED}
          renderBodySection={(labelMap, shouldHideMap) => (
            <StatusNotificationFormProperties.Fields
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          renderButtonSection={() => (
            <Box display='flex' justifyContent='flex-end'>
              <CngButton color='primary' size='medium' type='submit'>
                {getTranslatedText('FDAPN', 'saveButton')}
              </CngButton>
            </Box>
          )}
        />
      }
      dialogTitle={getTranslatedText('FDAPN', 'statusNotification')}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='md'
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default StatusNotificationDialog
