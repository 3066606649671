import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import { Box, Card, Grid, Tooltip, Divider } from '@material-ui/core'
import PackagingFormProperties from './PackagingFormProperties'
import useCustomYupValidationResolver from '../../../components/aciacehighway/hooks/useCustomYupValidationResolver'
import { FormProvider, useForm } from 'react-hook-form'

const {
  button: { CngButton, CngIconButton },
  CngGridItem
} = components

const { initialValues, makeValidationSchema } = PackagingFormProperties.formikProps

function PackagingEntry(props) {
  const {
    index,
    isLast,
    onAddPackaging,
    onDeletePackaging,
    packagingDTO,
    packaging,
    showNotification,
    shouldHideMap
  } = props

  const { translate } = useTranslation(Namespace.FDAPN)

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} md>
        <Grid container spacing={1}>
          <PackagingFormProperties.Fields showNotification={showNotification} index={index} shouldHideMap={shouldHideMap}/>
        </Grid>
      </CngGridItem>
      <CngGridItem xs={12} md='auto'>
        <Grid item xs={12}>
          <Grid container justify='space-between' spacing={2}>
            <Grid item xs='auto'>
              <Grid container spacing={1}>
                <CngGridItem xs='auto'>
                  <CngIconButton
                    type='outlined'
                    icon={['fal', 'plus']}
                    onClick={() => onAddPackaging(initialValues)}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs='auto'>
                  <CngIconButton
                    type='outlined'
                    icon={['fal', 'copy']}
                    onClick={() => onAddPackaging({ ...packagingDTO, id: undefined })}
                    size='small'
                  />
                </CngGridItem>
                {!isLast && (
                  <Grid item xs='auto'>
                    <Tooltip placement='bottom' title='Delete'>
                      <span>
                        <CngIconButton
                          icon={['fal', 'trash']}
                          onClick={() => onDeletePackaging(packagingDTO)}
                          size='small'
                          type='outlined'
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CngGridItem>
    </Grid>
  )
}

export default PackagingEntry
