import React, { useEffect, useState, useContext } from 'react'
import FdapnApiUrls from 'src/apiUrls/FdapnApiUrls'
import FdapnStatusNotificationApiUrls from 'src/apiUrls/FdapnStatusNotificationApiUrls'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import ViewContent from './ViewContent'
import pathMap from 'src/paths/pathMap'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import ConfirmDialog from './../../common/ConfirmDialog'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'

const {
  button: { CngButton },
  form: { CngViewForm },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

function ViewPage(props) {
  const { history, showNotification } = props
  const { id } = useParams()
  const [data, setData] = useState(null)
  const { createRecord, deleteRecord, fetchRecord, fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const [lookups, setLookups] = useState(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const { getTranslatedText } = useContext(FdaPNTranslationContext)

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(
        FdapnApiUrls.GET,
        id,
        (res) => res.content[0]
      ),
      //Status Notification
      fetchRecords.execute(
        FdapnStatusNotificationApiUrls.GET,
        {
          data: { headerId: id }
        },
        (res) => res.content
      ),
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_STATUS' }],
        undefined,
        'code'
      ),
      // Mode of Transport
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'FDA_PN_MOT' }],
        undefined,
        'code'
      ),
      // Reference Qualifier
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'FDA_PN_REF_ID_CODE' }],
        undefined,
        'code'
      ),
      // Yes Or No
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'Y_OR_N' }],
        undefined,
        'code'
      ),
      // Entry Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'FDA_PN_ENTRY_TYPE' }],
        undefined,
        'code'
      ),
      // Bill Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'FDA_PN_BILL_TYPE' }],
        undefined,
        'code'
      ),
      // Arrival Location
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          customData: { codeMType: 'DocPortCode' },
          filters: [
            {
              field: 'active',
              operator: 'equal',
              value: true
            },
            {
              field: 'set_indicator',
              operator: 'equal',
              value: 'FDAPN'
            }
          ],
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
    ]).then(
      ([
        data,
        statusNotificationInfo,
        status,
        mot,
        referenceQualifier,
        yesOrNo, entryType, billType,
        arrivalLocationList
      ]) => {
        if (data === undefined) {
          showNotification('error', 'Data not found.')
          history.push(pathMap.FDAPN_LIST_VIEW)
        }

        // const states = Object.keys(countries).reduce((acc, countryCode) => {
        //   acc[countryCode] = countryStates.filter(
        //     (state) => state.code === countryCode
        //   )

        //   return acc
        // }, {})

        setData({ ...data, statusNotificationInfo })
        setLookups({
          status,
          mot,
          referenceQualifier,
          yesOrNo,
          entryType,
          billType,
          arrivalLocationList
        })
      }
    )
  }, [])

  if (data === null) {
    return <CircularProgress />
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  // function getCountryStateLabel(country, state) {
  //   if (!lookups || !country || !state) return state

  //   // Check if country code exists in the lookup
  //   if (Object.keys(lookups.countries).includes(country)) {
  //     const result = lookups.countryStates[country].find(
  //       (countryState) => countryState.intlcode === state
  //     )

  //     return result ? result.descriptionEn : state
  //   } else {
  //     return state
  //   }
  // }

  function handleCloneManifest() {
    createRecord.execute(
      FdapnApiUrls.CLONE,
      data,
      (data) => {
        history.push(pathMap.FDAPN_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDeleteManifest() {
    deleteRecord.execute(
      FdapnApiUrls.DELETE,
      data,
      () => {
        showNotification('success', 'Delete succeeded')
        history.push(pathMap.FDAPN_LIST_VIEW)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  return (
    <>
      <Card>
        <CardContent>
          <ViewContent
            data={data}
            // getCountryStateLabel={getCountryStateLabel}
            getLookupValue={getLookupValue}
            id={id}
            getTranslatedText={getTranslatedText}
          />
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.FDAPN_LIST_VIEW)}
          >
            Back to manifest list
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            {data.status === '1005' && (
              <CngButton
                disabled={data.status !== '1005'}
                onClick={() => setConfirmDialogOpen(true)}
                startIcon={
                  <FontAwesomeIcon icon={['fal', 'trash']} size='sm' />
                }
                variant='outlined'
              >
                {getTranslatedText('FDAPN', 'deleteButton')}
              </CngButton>
            )}
            <CngButton
              onClick={handleCloneManifest}
              startIcon={<FontAwesomeIcon icon={['fal', 'copy']} size='sm' />}
              variant='outlined'
            >
              {getTranslatedText('FDAPN', 'cloneButton')}
            </CngButton>
            {data.status !== '1008' && (
              <CngButton
                color='primary'
                disabled={data.status === '1008'}
                startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
                onClick={() =>
                  history.push(`${pathMap.FDAPN}/edit/${id}`)
                }
              >
                {getTranslatedText('FDAPN', 'editButton')}
              </CngButton>
            )}
          </Box>
        </CardActions>
      </Card>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={handleDeleteManifest}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={getTranslatedText('FDAPN', 'deleteButton')}
      />
    </>
  )
}

export default ViewPage
