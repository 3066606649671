import { Box, Typography, Chip, Tooltip, useTheme, Grid, Card } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import FdapnProductMasterApiUrls from 'src/apiUrls/FdapnProductMasterApiUrls'
import React, { useEffect, useRef, useState, useContext } from 'react'
import FdapnKeys from 'src/constants/locale/key/Fdapn'
import Namespace from 'src/constants/locale/Namespace'

import Table from '../../../components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pathMap from 'src/paths/pathMap'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import moment from 'moment-timezone'
import { getStatusMetadata } from '../../../common/NACommon'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import ConfirmDialog from 'src/views/common/ConfirmDialog'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  filter: { BETWEEN, EQUAL, IN, LIKE }
} = constants

function TablePage(props) {
  const {
    showNotification,
    history,
    location,
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.FDAPN
  ])
  const tableRef = useRef(null)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, fdapn: null })
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const [lookups, setLookups] = useState(null)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { fetchRecords, createRecord, deleteRecord } = useServices()
  const [fdapnTemplateDialog, setFdapnTemplateDialog] = useState(false)

  useEffect(() => {
    Promise.all([
      // Party Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'FDA_PN_ENTRY_TYPE' }],
        undefined,
        'code'
      ),
      // Document Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'FDA_PN_BILL_TYPE' }],
        undefined,
        'code'
      ),
      // Arrival Location
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [
            { field: 'indicator', operator: EQUAL, value: 'ACI' }
          ],
          customData: { codeMType: 'CustomsOffice' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
    ]).then(
      ([
        entryType,
        billType,
        arrivalLocation
      ]) => {
        setLookups({
          entryType,
          billType,
          arrivalLocation
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const combineDateAndTime = (date, time) => {
    const dateTime = moment(date).set({
      hour: parseInt(time.substring(0, 2), 10),
      minute: parseInt(time.substring(2, 4), 10),
    });
    return dateTime.tz("Canada/Eastern").format('D MMM YYYY HH:mm');
  };

  const columns = [
    {
      field: 'productId',
      sortKey: 'productId',
      title: getTranslatedText('FDAPN', 'pgaProductId'),
    },
    {
      field: 'productNumber',
      sortKey: 'productNumber',
      title: getTranslatedText('FDAPN', 'pgaProductNumber'),
    },
    {
      field: 'anticipatedArrivalDate',
      sortKey: 'anticipatedArrivalDate',
      title: getTranslatedText('FDAPN', 'anticipatedArrivalDate'),
      render: (data) => combineDateAndTime(data.anticipatedArrivalDate, data.anticipatedArrivalTime)
      // data.anticipatedArrivalDate && moment(data.anticipatedArrivalDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: getTranslatedText('FDAPN', 'createdDate'),
      render: (data) =>
        data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    }
  ]

  const filters = [
    {
      label: getTranslatedText('FDAPN', 'productId'),
      type: 'textfield',
      name: 'productId',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('FDAPN', 'productNumber'),
      type: 'textfield',
      name: 'productNumber',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('FDAPN', 'anticipatedArrivalDate'),
      type: 'daterange',
      name: 'anticipatedArrivalDate',
      operatorType: BETWEEN
    },
    {
      label: getTranslatedText('FDAPN', 'createdDate'),
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    }
  ]

  function handleCloneProductMaster(data) {
    // data.cloneFrom = data.tripNum;
    createRecord.execute(
      FdapnProductMasterApiUrls.CLONE,
      data,
      (data) => {
        if (data) {
          const usedPartyData = data.party && data.party.map(
            (partyDTO, index) => ({ ...partyDTO, _id: index + 1 }))
          data.party = usedPartyData

          const usedPackagingData = data.packaging && data.packaging.map(
            (packagingDTO, index) => ({ ...packagingDTO, _id: index + 1 }))
          data.packaging = usedPackagingData

          const usedContainerInfoData = data.containerInfo && data.containerInfo.map(
            (containerInfoDTO, index) => ({ ...containerInfoDTO, _id: index + 1 }))
          data.containerInfo = usedContainerInfoData

          const usedAffirmationData = data.affirmation && data.affirmation.map(
            (affirmationDTO, index) => ({ ...affirmationDTO, _id: index + 1 }))
          data.affirmation = usedAffirmationData

          const usedProductCondData = data.productCond && data.productCond.map(
            (productCondDTO, index) => ({ ...productCondDTO, _id: index + 1 }))
          data.productCond = usedProductCondData
        }

        history.push(pathMap.FDAPN_PRODUCTMASTER_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDeleteFDAPN() {
    if (confirmDialog.fdapn) {
      deleteRecord.execute(
        FdapnProductMasterApiUrls.DELETE,
        confirmDialog.fdapn,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, fdapn: null })
          setFdapnTemplateDialog(false)
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.FDAPN_PRODUCTMASTER_ADD_VIEW)
            },
            label: 'Create Product Master'
          },
          // {
          //   buttonProps: {
          //     color: 'primary',
          //     size: 'medium',
          //     startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
          //     // onClick: () => setIsf10TemplateDialog(true)
          //   },
          //   label: 'FDA PN Upload'
          // }
          // {
          //   buttonProps: {
          //     color: 'primary',
          //     size: 'medium',
          //     startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
          //     // onClick: () => setIsf10TemplateDialog(true)
          //   },
          //   label: 'FDA PN Template'
          // }
        ]}
        columns={columns}
        compact
        // exportData={{ url: ISF10ApiUrls.CUSTOM_EXPORT }}
        fetch={{ url: FdapnProductMasterApiUrls.GET }}
        fetchFilters={[
          // { field: 'templateFlag', operator: EQUAL, value: '0' },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (rowData.status === '1008') {
            history.push(`${location.pathname}/view/${rowData.id}`)
          }
          else {
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }
        }
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: getTranslatedText('FDAPN', 'editButton'),
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              rowData.status === '1008'
                ? getTranslatedText('FDAPN', 'editSentRecords')
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: getTranslatedText('FDAPN', 'cloneButton'),
            onClick: handleCloneProductMaster
          },
          // {
          //   label: getTranslatedText('FDAPN', 'printISF10'),
          //   icon: <FontAwesomeIcon icon={['fal', 'print']} />,
          //   // onClick: handlePrintIsf10
          // },
          {
            // disabled: (data) => data.status !== '1005',
            label: getTranslatedText('FDAPN', 'deleteButton'),
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (data) => setConfirmDialog({ open: true, fdapn: data }),
            // tooltip: (rowData) =>
            //   rowData.status !== '1005'
            //     ? getTranslatedText('FDAPN', 'deleteRecords')
            //     : null
          },
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
        checkboxSelection
        tablePreference={{ module: 'FDAPN', key: 'fdapn_product_master_table_visible_column' }}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, fdapn: null })}
        confirmDialog={handleDeleteFDAPN}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={getTranslatedText('FDAPN', 'deleteButton')}
      />
      {/*<TemplateSelectDialogWithOption
        isfTemplateDialog={isf10TemplateDialog}
        setIsfTemplateDialog={setIsf10TemplateDialog}
        setConfirmDialog={setConfirmDialog}
        showNotification={showNotification}
        isTable={true}
        location={location}
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: 'Edit',
            onClick: (rowData) =>
              history.push(pathMap.ACEISF10+`/edit/${rowData.id}`),
            tooltip: () => 'edit'
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: (rowData) =>
              setConfirmDialog({ open: true, isf10: rowData }),
            tooltip: () => 'delete'
          }
        ]}
      /> */}
    </>
  )
}

export default TablePage
