import makeValidationSchema from './BolMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import FdapnKeys from 'src/constants/locale/key/Fdapn'
import { Card, CardContent, Grid } from '@material-ui/core'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  _id: "",
  pnHeaderId: "",
  billType: "",
  bolIssuerCode: "",
  bolNumber: "",
  version: "",
  pnConfirmationNumber: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index
}) {
  const { translate } = useTranslation(Namespace.FDAPN)
  const { getTranslatedText } = useContext(FdaPNTranslationContext)
  const { getValues, setValue, reset, trigger } = useFormContext();
  const { billType } = useWatch()

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.billType}>
            <CngCodeMasterAutocompleteField
              required
              name={typeof index === 'number' ? `bol.${index}.billType` : 'billType'}
              label={getTranslatedText('FDAPN', 'billType')}
              codeType='FDA_PN_BOL_BILL_TYPE'
              size='small'
              disabled={disabled}
              onChange={(e) => {
                e === 'T' && setValue('bolIssuerCode', '');
              }}
              key={billType}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.bolIssuerCode}>
            <CngTextField
              required={billType !== 'T'}
              name={typeof index === 'number' ? `bol.${index}.bolIssuerCode` : 'bolIssuerCode'}
              label={getTranslatedText('FDAPN', 'bolIssuerCode')}
              inputProps={{ maxLength: 4 }}
              onChange={(e) => {
                setValue('bolIssuerCode', e.target.value.toUpperCase())
              }}
              size='small'
              disabled={billType === 'T'}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.bolNumber}>
            <CngTextField
              required
              name={typeof index === 'number' ? `bol.${index}.bolNumber` : 'bolNumber'}
              label={getTranslatedText('FDAPN', 'bolNumber')}
              inputProps={{ maxLength: 50 }}
              onChange={(e) => {
                setValue('bolNumber', e.target.value.toUpperCase())
              }}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const BillOfLadingFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default BillOfLadingFormProperties
