import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    billType: Yup.string().nullable(),

    bolIssuerCode: Yup.string().when('billType', {
      is: (value) => value !== "T",
      then: Yup.string().required(requiredMessage).nullable().matches("^[a-zA-Z0-9\-]*$", "Should be a valid 4 character/digits Issuer Code"),
      otherwise: Yup.string()
    }).nullable(),
    bolNumber: Yup.string().required(requiredMessage).nullable().matches("^[a-zA-Z0-9\-]*$", "Should be a valid 50(Maximum) character/digits Bill Of Lading Number"),
  })
}

export default makeValidationSchema
