import React, { useState, useEffect, useContext } from 'react'
import { components, useServices, useTranslation } from 'cng-web-lib'
import { Box, Grid, Paper } from '@material-ui/core'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { FdaPNTranslationContext } from './../contexts/FDAPNTranslationContext.js'
import FdapnApiUrls from 'src/apiUrls/FdapnApiUrls'
import ErrorMessageSection from 'src/views/common/ErrorMessageSection'

const {
  button: { CngButton },
  dropzone: { CngFileUpload },
  CngDialog
} = components

function CSVUploadDialog(props) {
  const { onClose, open, showNotification } = props

  const [files, setFiles] = useState([])
  const [errorMessages, setErrorMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const { securedSendRequest } = useServices()
  const { getTranslatedText } = useContext(FdaPNTranslationContext)

  useEffect(() => {
    setFiles([])
    setErrorMessages([])
  }, [open]);

  function handleUploadFDAPN() {

    if (files.length > 0) {
      try {
        setLoading(true)

        let fileForUserDetails = FileForUserGetUserDetails();
        const formData = new FormData()

        formData.append('file', files[0].file)
        if (fileForUserDetails != null && fileForUserDetails != undefined) {
          formData.append("fileForUserDetails", JSON.stringify(fileForUserDetails))
        } else {
          formData.append("fileForUserDetails", "{}")
        }
        // formData.append('manifestId',manifestId)

        securedSendRequest.execute('POST', FdapnApiUrls.UPLOAD, formData,
          (response) => {
            if (response.status === 200) {
              if (response.data) {
                const errorMessages = response.data.errorMessages

                if (errorMessages) {
                  setErrorMessages(errorMessages)
                  setFiles([])
                }
              } else {
                showNotification('success', 'Record(s) successfully uploaded')
                onClose()
                open.tableRef.current.performRefresh()
              }
            }
          },
          () => {
            showNotification('error', 'Record(s) failed to upload.')
            setFiles([])
          },
          () => setLoading(false)
        )
      } catch (error) {
        console.log(error)
      }
    } else {
      showNotification('error', 'File is empty, please choose a valid file.')
    }
  }

  function escapeCsvField(field) {
    const fieldStr = field.toString();
    if (fieldStr.includes(',') || fieldStr.includes('\n') || fieldStr.includes('"')) {
      return `"${fieldStr.replace(/"/g, '""')}"`;
    }
    return fieldStr;
  }

  function formatCsvContent(rows) {
    const formattedRows = rows.map(row => row.map(escapeCsvField).join(','));
    return formattedRows.join('\n');
  }

  const data = [
    [
      'S No', 'Acct ID', 'User ID', 'Mode of Transportation', 'Entry Type', 'Reference Identifier',
      'Reference Identifier No.', 'Filer/Issuer Code', 'Bill Type', 'Carrier Code', 'Bill Type Indicator',
      'Issuer Code', 'Bill of Lading No.', 'Prior Notice S No.', 'Product No.', 'Commercial Desc',
      'Government Agency Processing code', 'Commodity Desc', 'Country of Production/Place of Growth',
      'Country of Shipment', 'Arrival Location', 'Arrival Date', 'Arrival Time', 'Package Tracking Code',
      'Package Tracking No.', 'Container No.', 'Party Type', 'Party Identifier Type', 'Party Identifier Number',
      'Party Name', 'Address1', 'Address2', 'Apartment/Suite No.', 'City', 'State/Province', 'Country', 'Postal Code',
      'Contact Person', 'Telephone Number', 'Email', 'Base Quantity', 'Base UOM', 'Packaging Qualifier', 'Quantity',
      'UOM', 'Affirmation Compliance Code', 'Affirmation Compliance Qualifier', 'End'
    ],
    [
      'Header Information', 'AN-AlphaNumeric Characters', 'AN-AlphaNumeric Characters', 'M-Mandatory',
      'O-Optional', 'C-Conditional', 'AN-AlphaNumeric Characters', 'N-Numeric', 'A-Alpha Characters',
      'X-AlphaNumeric or Special Characters', '', '', '', '', '', 'Prior Notice Information', '', '', '', '', '', '',
      '', '', '', '', 'Container Information', 'Party Information', '', '', '', '', '', '', '', '', '', 'Packaging Information',
      '', '', 'Affirmation Compliance Information', '', ''
    ],
    [
      'Length of each field', 'O,6AN', 'O,10AN', 'C,2AN', 'C,2AN', 'M,3AN', 'M,1..46X', 'C,4AN', 'C,1X', 'C,4AN', 'C,1X',
      'C,4AN', 'C,0..50X', '1,2,…', 'M,1..19X', 'M,1..70X', 'M,3X', 'M,1..57X', 'M,2X', 'M,2X', 'O,0..50X', 'DD-MM-YYY',
      'HHMM', 'O,4AN', 'O,0..50AN', 'C,0..20AN', 'M,1..3X', 'O,2..3X', 'O,0..15X', 'M,1..32X', 'C,0 23X', 'C,0..32X',
      'C,0..5X', 'M,1..21X', 'C,0..3X', 'M,2A', 'C,0..9X', 'M,0 23X', 'M,0..15X', 'M,0..35X', 'M,1..12N', 'M,1..5X',
      'M,1N', 'M,1..12N', 'M,1..5X', 'M,3A', 'M,1..30AN', 'Always Use "End"'
    ],
    [
      '1', 'iilt', 'iilt001', '50', '11', 'BOL', '48008', '1Z52', 'T', '5X', '', '', '', '1', '54YCY99',
      'Super Nutrient Personalised Gummies. Product code 41BYY99', 'DSU', 'Super Nutrient Personalised Gummies. Product code 41BYY99',
      'GB', 'US', '2704', '25-05-2024', '1200', 'FDXE', '275026341789', '', 'PNS', '', '', 'Rem3dy Health Ltd', '30 Floodgate Street', '', '',
      'Birmingham', '', 'GB', 'B5 5SL', 'Shipping Dept', '60176432928', 'fdapn@email.com', '12', 'G', '1', '28', 'BX', 'PFR',
      '16194086582', 'End'
    ],
    [
      '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      'FD1', '', '', 'Ari Tulla', '2930 Clay St', '', '', 'San Francisco', 'CA', 'US', '94115', 'Ari Tulla', '7543489533',
      'nourished@elo.health', '', '', '', '', '', '', '', ''
    ],
    [
      '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      'UC', '', '', 'Ari Tulla', '2930 Clay St', '', '', 'San Francisco', 'CA', 'US', '94115', 'Ari Tulla', '7543489533',
      'nourished@elo.health', '', '', '', '', '', '', '', ''
    ],
    [
      '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      'PNT', '', '', 'Ari Tulla', '2930 Clay St', '', '', 'San Francisco', 'CA', 'US', '94115', 'Ari Tulla', '7543489533',
      'nourished@elo.health', '', '', '', '', '', '', '', ''
    ],
    [
      '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      'MF', '', '', 'Ari Tulla', '2930 Clay St', '', '', 'San Francisco', 'CA', 'US', '94115', 'Ari Tulla', '7543489533',
      'nourished@elo.health', '', '', '', '', '', '', '', ''
    ],
    [
      '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      'DEQ', '', '', 'Ari Tulla', '2930 Clay St', '', '', 'San Francisco', 'CA', 'US', '94115', 'Ari Tulla', '7543489533',
      'nourished@elo.health', '', '', '', '', '', '', '', ''
    ],
    [
      '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      'DFP', '', '', 'Ari Tulla', '2930 Clay St', '', '', 'San Francisco', 'CA', 'US', '94115', 'Ari Tulla', '7543489533',
      'nourished@elo.health', '', '', '', '', '', '', '', ''
    ],
    [
      '2', 'iilt', 'iilt001', '50', '11', 'BOL', '48009', '1Z52', 'T', '5X', '', '', '', '1', '54YCY99',
      'Super Nutrient Personalised Gummies. Product code 41BYY99', 'DSU', 'Super Nutrient Personalised Gummies. Product code 41BYY99',
      'GB', 'US', '2704', '25-05-2024', '1200', 'FDXE', '275026341789', '', 'PNS', '', '', 'Rem3dy Health Ltd', '30 Floodgate Street', '', '',
      'Birmingham', '', 'GB', 'B5 5SL', 'Shipping Dept', '60176432928', 'fdapn@email.com', '12', 'G', '1', '28', 'BX', 'PFR',
      '16194086582', 'End'
    ],
    [
      '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      'FD1', '', '', 'Ari Tulla', '2930 Clay St', '', '', 'San Francisco', 'CA', 'US', '94115', 'Ari Tulla', '7543489533',
      'nourished@elo.health', '', '', '', '', '', '', '', ''
    ],
    [
      '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      'UC', '', '', 'Ari Tulla', '2930 Clay St', '', '', 'San Francisco', 'CA', 'US', '94115', 'Ari Tulla', '7543489533',
      'nourished@elo.health', '', '', '', '', '', '', '', ''
    ],
    [
      '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      'PNT', '', '', 'Ari Tulla', '2930 Clay St', '', '', 'San Francisco', 'CA', 'US', '94115', 'Ari Tulla', '7543489533',
      'nourished@elo.health', '', '', '', '', '', '', '', ''
    ],
    [
      '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      'MF', '', '', 'Ari Tulla', '2930 Clay St', '', '', 'San Francisco', 'CA', 'US', '94115', 'Ari Tulla', '7543489533',
      'nourished@elo.health', '', '', '', '', '', '', '', ''
    ],
    [
      '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      'DEQ', '', '', 'Ari Tulla', '2930 Clay St', '', '', 'San Francisco', 'CA', 'US', '94115', 'Ari Tulla', '7543489533',
      'nourished@elo.health', '', '', '', '', '', '', '', ''
    ],
    [
      '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      'DFP', '', '', 'Ari Tulla', '2930 Clay St', '', '', 'San Francisco', 'CA', 'US', '94115', 'Ari Tulla', '7543489533',
      'nourished@elo.health', '', '', '', '', '', '', '', ''
    ],
  ];

  const formattedCSVContent = formatCsvContent(data);

  const downloadFile = () => {
    const blob = new Blob([formattedCSVContent], { type: "text/csv" })
    const element = document.createElement("a");
    element.href = window.URL.createObjectURL(blob)
    element.download = "FDAPN_SampleUpload.csv";
    document.body.appendChild(element);
    element.click();
    element.parentNode.removeChild(element);
  }

  return (
    <CngDialog
      dialogTitle={getTranslatedText('FDAPN', 'uploadFDAPN')}
      dialogContent={
        <Paper variant='outlined'>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CngFileUpload
                  accept={['.csv']}
                  files={files}
                  maxFiles={1}
                  maxSize={5.3e+7}
                  onFileSelect={(file) => setFiles(file)}
                  showFormFields={false}
                  moreActions={[
                    {
                      action: 'remove',
                      name: 'Remove',
                      icon: ['fal', 'trash']
                    }
                  ]}
                  onDropRejected={(errorMessages) => showNotification('error', errorMessages)}
                />
              </Grid>

              <ErrorMessageSection errorMessages={errorMessages} />

              <Grid item xs={12}>
                <Grid container justify='flex-end' spacing={2}>
                  <Grid item xs='auto'>
                    <CngButton size='medium' variant='outlined' onClick={downloadFile}>
                      Download template CSV
                    </CngButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      }
      dialogAction={
        <>
          <CngButton color='secondary' disabled={loading} onClick={onClose}>
            {getTranslatedText('FDAPN', 'discardButton')}
          </CngButton>
          <CngButton
            color='primary'
            disabled={loading}
            onClick={handleUploadFDAPN}
            shouldShowProgress={loading}
          >
            {getTranslatedText('FDAPN', 'saveButton')}
          </CngButton>
        </>
      }
      fullWidth
      maxWidth='lg'
      open={open.open}
      onClose={onClose}
      shouldShowCloseButton
    />
  )
}

export default CSVUploadDialog
