import React, { useEffect, useState, useContext, useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, useServices, constants } from 'cng-web-lib'
import { Grid, Divider, Typography, Tooltip } from '@material-ui/core'
import { FdaPNTranslationContext } from '../../contexts/FDAPNTranslationContext.js'
import { useLocation } from 'react-router-dom'
import CngSection from '../../../../components/cngcomponents/CngSection.js'
import BillOfLadingFormProperties from '../BillOfLadingFormProperties.js';

const {
    button: { CngButton, CngIconButton },
    form: {
        field: {
            CngCheckboxField,
            CngTextField,
            CngDateField,
            CngTimeField,
            CngCodeMasterAutocompleteField,
            CngCountryAutocompleteField,
            CngSelectField,
            CngUomAutocompleteField
        },
    },
    CngGridItem,
} = components

const {
    filter: { LIKE, EQUAL, IN }
} = constants

const { initialValues } = BillOfLadingFormProperties.formikProps

function ActionCodeSection(props) {
    const { disabled, shouldHideMap, user, blTypes, showNotification } = props
    const { getValues, setValue, reset, trigger } = useFormContext();
    const { securedSendRequest } = useServices()
    const { getTranslatedText } = useContext(FdaPNTranslationContext)
    const location = useLocation();
    const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false
    const { actionCode } = useWatch()

    return (
        <CngSection title={getTranslatedText('FDAPN', 'actionCode')} subheader={
            <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>*</Typography>
                Mandatory fields
            </Typography>
        }>
            <CngCodeMasterAutocompleteField
                required
                name='actionCode'
                label={getTranslatedText('FDAPN', 'actionCode')}
                disabled={disabled}
                codeType='FDA_PN_ACTION_CODE'
                size='small'
                key={actionCode}
            />
        </CngSection>
    )
}

export default ActionCodeSection